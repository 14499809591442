export default function mapScore(score) {
    let scoreHome = score.fullTime.home !== null ? score.fullTime.home : "-";
    let scoreAway = score.fullTime.away !== null ? score.fullTime.away : "-";

    if (score.penalties) {
        scoreHome = score.fullTime.home - score.penalties.home;
        scoreAway = score.fullTime.away - score.penalties.away;
    }

    return scoreHome + " : " + scoreAway;
}
