import * as constant from "../../constants";
import * as util from "../../utils/utils";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import AnimatedScore from "../AnimatedScore";

function MatchesConfrontationBets({ results, bets, confrontationBets }) {
    const { t } = useTranslation("translation");

    function renderLiveSpinner(status) {
        if (status === constant.matchStatus.in_play) {
            return <Spinner animation="grow" variant="danger" size="sm" />;
        }
    }

    const matchesTable = results.matches.map((match, index) => (
        <tr key={match.id}>
            <td id="matches__matchdetails-bets" className="fs-small">
                {util.convertDate(match.utcDate)}
                <br />
                {match.venue}
            </td>
            <td id="matches__live-ticker" className="align-center">
                {renderLiveSpinner(match.status)}
            </td>
            <td id="matches__teamname-home">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(confrontationBets[index].team1)}
                    alt={confrontationBets[index].team1}
                    onError={util.mapDummyFlag}
                />
                <span
                    className={util.getClassRoundPass(match, "home")}
                    style={{ fontWeight: "normal" }}
                >
                    {t(util.mapTeamName(confrontationBets[index].team1))}
                </span>
            </td>
            <td id="matches__score" className="fs-large align-center">
                {util.mapScoreBet(bets[index].score)}
            </td>
            <td id="matches__teamname-away" className="align-right">
                <span
                    className={util.getClassRoundPass(match, "away", "desktop")}
                    style={{ fontWeight: "normal" }}
                >
                    {t(util.mapTeamName(confrontationBets[index].team2))}
                </span>
                <img
                    className="flag--right-aligned"
                    src={util.mapFlag(confrontationBets[index].team2)}
                    alt={confrontationBets[index].team2}
                    onError={util.mapDummyFlag}
                />
                <span
                    className={util.getClassRoundPass(match, "away", "mobile")}
                    style={{ fontWeight: "normal" }}
                >
                    {t(util.mapTeamName(confrontationBets[index].team2))}
                </span>
            </td>
            <td id="matches__bet" className="fs-large align-center">
                <span className={util.getClassLiveResult(match.status)}>
                    <AnimatedScore score={util.mapScore(match.score)} />
                </span>
            </td>
            <td id="matches__points" className="align-center">
                {bets[index].points}
            </td>
        </tr>
    ));

    return (
        <Container>
            <Table>
                <thead>
                    <tr className="no-border" key={v4()}>
                        <th id="matches__matchdetails-bets">{t("matches.header.matchDetails")}</th>
                        <th id="matches__live-ticker" />
                        <th id="matches__result" className="align-center" colSpan={3}>
                            {t("matches.header.bet")}
                        </th>
                        <th id="matches__bet" className="align-center">
                            {util.isMediaSizeTablet()
                                ? t("matches.header.result")
                                : t("matches.header.resultShort")}
                        </th>
                        <th id="matches__points" className="align-center">
                            {util.isMediaSizeTablet()
                                ? t("matches.header.points")
                                : t("matches.header.pointsShort")}
                        </th>
                    </tr>
                </thead>
                <tbody>{matchesTable}</tbody>
            </Table>
        </Container>
    );
}

export default MatchesConfrontationBets;
