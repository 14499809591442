import React from "react";
import Alert from "react-bootstrap/Alert";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        localStorage.clear();
        // You can also log error messages to an error reporting service here
    }

    render() {
        const { t } = this.props;
        if (this.state.errorInfo) {
            return (
                // You can render any custom fallback UI
                <div id="serverErrorAlert">
                    <Alert key="danger" variant="danger">
                        <img
                            src="/images/icons/kreuz.png"
                            alt="Search"
                            height={"25px"}
                            style={{
                                verticalAlign: "bottom"
                            }}
                        />

                        <p id="serverErrorText">{t("bets.errorMessages.runtimeError")}</p>
                    </Alert>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);
