import { z } from "zod"; // Validation library

const contactFormSchema = (t) => {
    return z.object({
        name: z
            .string()
            .trim()
            .min(3, { message: t("contact.errorMessage.nameRequired") }),
        email: z
            .string()
            .trim()
            .email({ message: t("contact.errorMessage.emailInvalid") }),
        subject: z
            .string()
            .trim()
            .min(3, { message: t("contact.errorMessage.subjectRequired") }),
        message: z
            .string()
            .trim()
            .min(3, { message: t("contact.errorMessage.messageRequired") })
    });
};

export default contactFormSchema;
