import GaugeComponent from "react-gauge-component";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CountUp from "react-countup";

import payouts from "../../data/payoutDefinition.json";

import { useTranslation } from "react-i18next";

function SpeedometerCard({ bets }) {
    const { t } = useTranslation("translation", { keyPrefix: "home.kpi" });

    return (
        <Row>
            <Col>
                <Card
                    bg="avisporra"
                    style={{
                        margin: "auto",
                        textAlign: "center",
                        marginTop: "1rem",
                        height: "15rem"
                    }}
                >
                    <Card.Header as="h5" style={{ color: "#003566" }}>
                        <img
                            src="/images/icons/teilnehmer.png"
                            alt="Search"
                            height={"25px"}
                            style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                                verticalAlign: "bottom"
                            }}
                        />{" "}
                        {t("speedometer.title")}
                    </Card.Header>
                    <Card.Body>
                        <div id="speedometer">
                            <GaugeComponent
                                type="semicircle"
                                arc={{
                                    padding: 0.02,
                                    subArcs: [
                                        { limit: 50, color: "#FF0000" },
                                        { limit: 150, color: "#FFD20C" },
                                        { color: "#00BA5D" }
                                    ]
                                }}
                                pointer={{ type: "arrow", color: "#003566", animationDelay: 0.5 }}
                                labels={{
                                    valueLabel: {
                                        style: { fontSize: "50px", fill: "#003566", textShadow: 0 },
                                        formatTextValue: (value) => value + ""
                                    },
                                    tickLabels: {
                                        type: "outer",
                                        valueConfig: {
                                            formatTextValue: (value) => value,
                                            fontSize: 8
                                        },
                                        ticks: [{ value: 50 }, { value: 150 }]
                                    }
                                }}
                                value={bets.length}
                                minValue={0}
                                maxValue={250}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card
                    bg="avisporra"
                    style={{
                        margin: "auto",
                        textAlign: "center",
                        marginTop: "1rem",
                        height: "15rem"
                    }}
                >
                    <Card.Header as="h5" style={{ color: "#003566" }}>
                        <img
                            src="/images/icons/euro.png"
                            alt="Search"
                            height={"25px"}
                            style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                                verticalAlign: "bottom"
                            }}
                        />{" "}
                        {t("prizepool.title")}
                    </Card.Header>
                    <Card.Body>
                        <div id="prizepool">
                            <CountUp duration={2.5} className="counter" end={bets.length * 10} />
                            <span>{" €"}</span>
                        </div>
                        <Card.Subtitle className="mb-2" style={{ marginTop: "1rem" }}>
                            {t("prizepool.subtitle")}
                        </Card.Subtitle>
                        <Card.Text>
                            <img
                                src={"/images/medals/gold.png"}
                                alt={1}
                                onError={(i) => (i.target.style.display = "none")}
                            />
                            &nbsp;&nbsp;
                            <CountUp
                                duration={2.5}
                                className="counter"
                                end={bets.length * 10 * payouts[0].decimal}
                            />
                            <span>{" €"}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                                src={"/images/medals/silver.png"}
                                alt={2}
                                onError={(i) => (i.target.style.display = "none")}
                            />
                            &nbsp;&nbsp;
                            <CountUp
                                duration={2.5}
                                className="counter"
                                end={bets.length * 10 * payouts[1].decimal}
                            />
                            <span>{" €"}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                                src={"/images/medals/bronze.png"}
                                alt={2}
                                onError={(i) => (i.target.style.display = "none")}
                            />
                            &nbsp;&nbsp;
                            <CountUp
                                duration={2.5}
                                className="counter"
                                end={bets.length * 10 * payouts[2].decimal}
                            />
                            <span>{" €"}</span>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default SpeedometerCard;
