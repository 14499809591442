import * as util from "../../utils/utils";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

function Confrontation({ results, bets }) {
    const { t } = useTranslation("translation");

    if (!results.additionals.confrontations || !bets) {
        return null;
    }

    results.additionals.confrontations.forEach((res, indexResult) => {
        bets.forEach((bet, indexBet) => {
            if (
                (bet.team1 === res.homeTeam || bet.team1 === res.awayTeam) &&
                (bet.team2 === res.homeTeam || bet.team2 === res.awayTeam)
            ) {
                util.swapArrayPosition(bets, indexBet, indexResult);
                if (bet.team1.name !== res.homeTeam.name) {
                    bets[indexResult].team1 = res.homeTeam;
                    bets[indexResult].team2 = res.awayTeam;
                }
            }
        });
    });

    const confrontationTable = results.additionals.confrontations.map((confrontation, index) => (
        <tr key={index}>
            <td id="confrontation__description">
                {t("bets.confrontations.confrontation")} {index + 1}
            </td>
            <td id="confrontation__teamname">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(confrontation.homeTeam)}
                    alt={confrontation.homeTeam}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(confrontation.homeTeam))}</span>
            </td>
            <td id="confrontation__teamname">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(confrontation.awayTeam)}
                    alt={confrontation.awayTeam}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(confrontation.awayTeam))}</span>
            </td>
            <td id="confrontation__bet">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(bets[index].team1)}
                    alt={bets[index].team1.name}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(bets[index].team1))}</span>
            </td>
            <td id="confrontation__bet">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(bets[index].team2)}
                    alt={bets[index].team2.name}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(bets[index].team2))}</span>
            </td>
            <td id="confrontation__points" className="align-center">
                {bets[index].points}
            </td>
        </tr>
    ));

    return (
        <Container>
            <Table>
                <thead>
                    <tr className="no-border" key={v4()}>
                        <th id="confrontation__description" />
                        <th id="confrontation__teamname" colSpan={2}>
                            {t("matches.header.result")}
                        </th>
                        <th id="confrontation__bet" colSpan={2}>
                            {t("matches.header.bet")}
                        </th>
                        <th id="confrontation__points" className="align-center">
                            {t("matches.header.points")}
                        </th>
                    </tr>
                </thead>
                <tbody>{confrontationTable}</tbody>
            </Table>
        </Container>
    );
}

export default Confrontation;
