// source: https://www.youtube.com/watch?v=cc_xmawJ8Kg

// import { SubmitHandler, useForm } from "react-hook-form"; // Typescript
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { zodResolver } from "@hookform/resolvers/zod";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

import contactFormSchema from "../utils/contactFormSchema";

// type FormFields = z.infer<typeof schema>; // Typescript
const encodeFormData = (formData) => {
    formData.name = encodeURIComponent(formData.name);
    formData.email = encodeURIComponent(formData.email);
    formData.subject = encodeURIComponent(formData.subject);
    formData.message = encodeURIComponent(formData.message);

    return formData;
};

const Contact = () => {
    const { t } = useTranslation("translation", { keyPrefix: "contact" });

    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: "",
        type: ""
    });

    // Shows alert message for form submission feedback
    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });

        // Hide alert after 5 seconds
        setTimeout(() => {
            setAlertInfo({ display: false, message: "", type: "" });
        }, 5000);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    const i18n = {
        name: t("name"),
        subject: t("subject"),
        message: t("message"),
        subjectPrefix: t("contactFormSubject"),
        header: t("contactFormHeader"),
        answer: t("contactFormAnswer"),
        buttonText: t("contactFormButtonText")
    };

    // const form = useForm<FormFields>(); // Typescript
    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors, isSubmitting }
    } = useForm({
        resolver: zodResolver(contactFormSchema(t)),
        defaultValues: {
            name: "",
            email: "",
            subject: "",
            message: ""
        }
    });

    // const onSubmit: SubmitHandler<FormFields> = async (data) => { console.log(data) }; // Typescript
    const onSubmit = async (formData) => {
        // send the form to the backend and an email can be sent.
        //   Then, when the backend has the confirmation of the email,
        //   we should let the user know that the email has been sent.

        encodeFormData(formData);

        formData.i18n = i18n;

        try {
            // build a post request sending the form data to the backend
            const response = await fetch(process.env.REACT_APP_BASE_URL + "message", {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const responseData = await response.json();

            if (!response.ok) {
                toggleAlert(t("response.error"), "danger");
                return;
            }

            if (responseData.errors) {
                // server-side validation failed
                const errors = responseData.errors;
                if (errors.email) {
                    toggleAlert(errors.email, "danger");
                    setError("email", { type: "server", message: errors.email });
                }
                return;
            }

            // Display success alert
            toggleAlert(t("response.success"), "success");
        } catch (error) {
            // Display error alert
            toggleAlert(t("response.error"), "danger");
        } finally {
            // Reset contact form fields after submission
            reset();
        }
    };

    return (
        <Container className="pageContainer">
            <div>
                <h2 id="pageHeaderText">{t("title")}</h2>
                <hr className="dashed" style={{ marginBottom: "2rem" }} />
                <div className="contact-form">
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-4">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Control
                                    {...register("name")}
                                    required
                                    type="text"
                                    placeholder={t("name")}
                                    className={
                                        errors.name
                                            ? "contact-form-field form-control is-invalid"
                                            : "contact-form-field form-control"
                                    }
                                />
                                {errors.name && (
                                    <Form.Control.Feedback type="invalid" className="">
                                        {errors.name.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Control
                                    {...register("email")}
                                    required
                                    type="text"
                                    placeholder={t("email")}
                                    className={
                                        errors.email
                                            ? "contact-form-field form-control is-invalid"
                                            : "contact-form-field form-control"
                                    }
                                />
                                {errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} md="12" controlId="validationCustom03">
                                <Form.Control
                                    {...register("subject")}
                                    required
                                    type="text"
                                    placeholder={t("subject")}
                                    className={
                                        errors.subject
                                            ? "contact-form-field form-control is-invalid"
                                            : "contact-form-field form-control"
                                    }
                                />
                                {errors.subject && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subject.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col} md="12" controlId="validationCustom04">
                                <Form.Control
                                    {...register("message")}
                                    as="textarea"
                                    rows={6}
                                    required
                                    type="text"
                                    placeholder={t("message")}
                                    className={
                                        errors.message
                                            ? "contact-form-field form-control is-invalid"
                                            : "contact-form-field form-control"
                                    }
                                />
                                {errors.message && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.message.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Row>
                        <Button disabled={isSubmitting} type="submit" variant="avisporra">
                            {isSubmitting ? `${t("loading")}...` : t("submit")}
                        </Button>
                    </Form>
                </div>
                {alertInfo.display && (
                    <div
                        className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
                        role="alert"
                    >
                        {alertInfo.message}
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setAlertInfo({ display: false, message: "", type: "" })} // Clear the alert when close button is clicked
                        ></button>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default Contact;
