import React from "react";
import "../../css/components/buttons/toggleButton.css";
import { withTranslation } from "react-i18next";

class ToggleButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: typeof this.props.checked === "boolean" ? this.props.checked : false,
            disabled: typeof this.props.disabled === "boolean" ? this.props.disabled : false,
            onlabel: this.props.onlabel || "On",
            offlabel: this.props.offlabel || "Off",
            onstyle: this.props.onstyle || "avisporra-yellow",
            offstyle: this.props.offstyle || "avisporra-blue",
            size: this.props.size || "",
            style: this.props.style || "",
            width: this.props.width || null,
            height: this.props.height || null
        };
    }

    componentDidUpdate(_, prevState) {
        const { checked } = this.props;
        if (typeof checked === "boolean" && checked !== prevState.checked) {
            this.setState({ checked });
        }
    }

    toggle = () => {
        this.state.checked ? this.off() : this.on();
    };
    off = () => {
        if (!this.state.disabled) {
            this.setState({
                checked: false
            });
            if (this.props.onChange) this.props.onChange(false);
        }
    };
    on = () => {
        if (!this.state.disabled) {
            this.setState({
                checked: true
            });
            if (this.props.onChange) this.props.onChange(true);
        }
    };
    enable = () => {
        this.setState({
            disabled: false
        });
    };
    disable = () => {
        this.setState({
            disabled: true
        });
    };

    render = () => {
        const { t } = this.props;
        let switchStyle = {};
        switchStyle.width = this.state.width + "px";
        switchStyle.height = this.state.height + "px";

        let labelStyle = {};
        if (this.state.height) labelStyle.lineHeight = "calc(" + this.state.height + "px * 0.8)";

        return (
            <div
                className={
                    "switch btn " +
                    (this.state.checked
                        ? "on btn-" + this.state.onstyle
                        : "off btn-" + this.state.offstyle) +
                    (this.state.size ? " btn-" + this.state.size : "") +
                    (this.state.style ? " " + this.state.style : "")
                }
                style={switchStyle}
                onClick={this.toggle}
            >
                <div className="switch-group">
                    <span
                        className={
                            "switch-on btn btn-" +
                            this.state.onstyle +
                            (this.state.size ? " btn-" + this.state.size : "")
                        }
                        style={labelStyle}
                    >
                        {t(this.state.onlabel)}
                    </span>
                    <span
                        className={
                            "switch-off btn btn-" +
                            this.state.offstyle +
                            (this.state.size ? " btn-" + this.state.size : "")
                        }
                        style={labelStyle}
                    >
                        {t(this.state.offlabel)}
                    </span>
                    <span
                        className={
                            "switch-handle btn " + (this.state.size ? "btn-" + this.state.size : "")
                        }
                    />
                </div>
            </div>
        );
    };
}

export default withTranslation()(ToggleButton);
