import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { MdOutlineWifiTetheringError } from "react-icons/md";
import ErrorBoundary from "./components/ErrorBoundary";

import Footer from "./components/Footer";

import "./css/app.css";
import "./css/bets.css";
import "./css/contact.css";
import "./css/countdownTimer.css";

import * as constant from "./constants";
import { SocketContext, socket } from "./services/socket";

import Navbar from "./components/Navbar";

import Home from "./pages/Home";
import Bets from "./pages/Bets";
import Ranking from "./pages/Ranking";
import Notifications from "./pages/Notifications";
import TournamentTree from "./pages/TournamentTree";
import PointsDistribution from "./pages/PointsDistribution";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";

import { useTranslation } from "react-i18next";
import useWindowResizeThreshold from "./hooks/useWindowResizeThreshold";

export default function App() {
    const [results, setResults] = useState([]);
    const [bets, setBets] = useState([]);
    const [updateTime, setUpdateTime] = useState("");
    const [isServerConnected, setIsServerConnected] = useState(true);

    const { t } = useTranslation("translation");

    useEffect(() => {
        socket.connect();

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        socket.connect();

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!isServerConnected) {
            setResults(
                localStorage.getItem("results") ? JSON.parse(localStorage.getItem("results")) : []
            );
            setBets(localStorage.getItem("bets") ? JSON.parse(localStorage.getItem("bets")) : []);
            setUpdateTime(
                localStorage.getItem("updateTime")
                    ? JSON.parse(localStorage.getItem("updateTime"))
                    : ""
            );
        }

        function onResultsEvent(data) {
            setResults(data);
            localStorage.setItem("results", JSON.stringify(data));
        }
        function onBetsEvent(data) {
            setBets(data);
            localStorage.setItem("bets", JSON.stringify(data));
        }
        function onUpdateTimeEvent(data) {
            setUpdateTime(data);
            localStorage.setItem("updateTime", JSON.stringify(data));
        }
        function onConnectionEvent() {
            setIsServerConnected(true);
        }

        socket.on("results", onResultsEvent);
        socket.on("bets", onBetsEvent);
        socket.on("updateTime", onUpdateTimeEvent);

        socket.on("connect", onConnectionEvent);

        socket.on("connect_error", (err) => {
            setIsServerConnected(false);
            console.log(`Socket connection error due to ${err.message}`);
        });

        return () => {
            socket.off("connect");
            socket.off("connect_error");
            socket.off("results", onResultsEvent);
            socket.off("bets", onBetsEvent);
            socket.off("updateTime", onUpdateTimeEvent);
        };
    }, [isServerConnected]);

    useWindowResizeThreshold(constant.mediaMaxSize.laptop);

    function renderServerConnectionError(serverStatus) {
        if (!serverStatus) {
            return (
                <>
                    <div id="serverErrorAlert">
                        <Alert key="danger" variant="danger">
                            <h4>
                                <MdOutlineWifiTetheringError />
                            </h4>
                            <p id="serverErrorText">{t("server.noConnection")}</p>
                        </Alert>
                    </div>
                </>
            );
        } else {
            return;
        }
    }

    return (
        <>
            <SocketContext.Provider value={socket}>
                <Navbar />
                <Container className="contentContainer">
                    {renderServerConnectionError(isServerConnected)}
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ErrorBoundary>
                                    <Home results={results} bets={bets} updateTime={updateTime} />
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path="/bets"
                            element={
                                <ErrorBoundary>
                                    <Bets results={results} bets={bets} updateTime={updateTime} />
                                </ErrorBoundary>
                            }
                        />

                        <Route
                            path="/ranking"
                            element={<Ranking results={results} bets={bets} />}
                        />
                        <Route path="/notifications" element={<Notifications />} />
                        <Route path="/pointsDistribution" element={<PointsDistribution />} />
                        <Route
                            path="/tournamentTree"
                            element={<TournamentTree results={results} />}
                        />
                        <Route path="/Contact" element={<Contact />} />
                        <Route path="/FAQ" element={<FAQ />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Container>
                <Footer />
            </SocketContext.Provider>
        </>
    );
}
