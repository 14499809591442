import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import payouts from "../../data/payoutDefinition.json";

import { useTranslation } from "react-i18next";

function PayoutCard({ bets }) {
    const { t } = useTranslation("translation", { keyPrefix: "home.after.payoutCard" });

    function getPayoutsTable() {
        bets.sort((a, b) => a.nickname.localeCompare(b.nickname));
        bets.sort((a, b) => b.points - a.points);
        return payouts.map((payout, index) => (
            <tr className="no-border" key={index}>
                <td className="align-middle text-center">
                    <img
                        src={renderMedal(index + 1)}
                        alt={index + 1}
                        onError={(i) => (i.target.style.display = "none")}
                    />
                </td>
                <td>{bets[index].nickname}</td>
                <td>{bets[index].points}</td>
                <td className="align-middle" id="payoutShare">{payout.share}</td>
                <td className="align-middle">{payout.payout}</td>
            </tr>
        ));
    }

    function renderLoadingSpinner() {
        return (
            <Spinner
                animation="border"
                variant="warning"
                style={{ display: "block", margin: "auto", marginTop: "2em" }}
            />
        );
    }

    function renderMedal(rank) {
        switch (rank) {
            case 1:
                return "/images/medals/gold.png";
            case 2:
                return "/images/medals/silver.png";
            case 3:
                return "/images/medals/bronze.png";
            case 4:
                return "/images/medals/bronze.png";
            default:
                return "";
        }
    }

    function renderPayoutsTable() {
        return (
            <Table size="sm" style={{ width: "100%", marginTop: "1em" }}>
                <thead>
                    <tr className="no-border">
                        <th className="align-middle text-center">{t("table.rank")}</th>
                        <th>{t("table.bettor")}</th>
                        <th>{t("table.points")}</th>
                        <th id="payoutShare">{t("table.share")}</th>
                        <th className="align-middle">{t("table.payout")}</th>
                    </tr>
                </thead>
                <tbody>{getPayoutsTable()}</tbody>
            </Table>
        );
    }

    return (
        <Card style={{ marginTop: "2rem", marginBottom: "2rem" }} bg="light" border="secondary">
            <Card.Header as="h5" style={{ color: "#003566" }}>
                <img
                    src="/images/icons/trophae.png"
                    alt="Search"
                    height={"25px"}
                    style={{ marginBottom: "0px", marginRight: "10px", verticalAlign: "bottom" }}
                />{" "}
                {t("title")}
            </Card.Header>
            <Card.Body>
                <Card.Text>{t("text")}</Card.Text>
                {bets.length === 0 ? renderLoadingSpinner() : renderPayoutsTable()}
            </Card.Body>
        </Card>
    );
}

export default PayoutCard;
