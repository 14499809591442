export default function camelize(sString) {
    sString = sString.trim();

    if (sString.indexOf(" ") === -1) return sString.toLowerCase();

    return sString
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
}
