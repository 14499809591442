import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../services/i18n";

function NavbarMain() {
    const [isShrunk, setShrunk] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [navTitle, setTitle] = useState();
    const [language, setLanguage] = useState(i18n.language ? i18n.language : "en");

    const { t } = useTranslation("translation", {
        keyPrefix: "pages.navbarTitle"
    });

    function changeLanguage(lng) {
        setLanguage(lng);
        i18n.changeLanguage(lng);
    }

    const location = useLocation();

    useEffect(() => {
        const handler = () => {
            setShrunk((isShrunk) => {
                if (!isShrunk && window.scrollY > 80) {
                    return true;
                }
                if (isShrunk && window.scrollY < 10) {
                    return false;
                }
                return isShrunk;
            });
        };

        switch (location.pathname) {
            case "/notifications":
                setTitle("notifications");
                break;
            case "/pointsDistribution":
                setTitle("pointsDistribution");
                break;
            case "/tournamentTree":
                setTitle("tournamentTree");
                break;
            case "/contact":
                setTitle("contact");
                break;
            case "/FAQ":
                setTitle("FAQ");
                break;
            default:
                setTitle("more");
                break;
        }

        window.addEventListener("scroll", handler);
    }, [location.pathname]);

    const navBarClass = isShrunk ? "shadow" : "";
    const logoClass = isShrunk ? "logoEMShrunk" : "logoEM";
    const dropdownSelected = t(navTitle) !== t("more") ? "dropDownSelected" : "";

    const handleDropdownSelection = () => {
        setExpanded(false);
    };

    return (
        <Navbar
            id="navbar"
            className={navBarClass}
            collapseOnSelect
            expand="lg"
            bg="light"
            variant="light"
            fixed="top"
            sticky="top"
            expanded={expanded}
        >
            <Container>
                <NavLink to="/">
                    <Navbar.Brand>
                        <img
                            id="navbar__logo-avisporra"
                            className={logoClass}
                            src="/images/Avisporra_Logo_Symbol.png"
                            alt={t("logo_alt")}
                        />
                        <img
                            id="navbar__logo-avisporra-text"
                            className={logoClass}
                            src="/images/Avisporra_Logo_Text.png"
                            alt={t("logo_alt")}
                        />
                    </Navbar.Brand>
                </NavLink>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto" defaultActiveKey="/">
                        <NavLink
                            onClick={() => handleDropdownSelection()}
                            to="/"
                            className="navbar-link fs-large"
                        >
                            {t("home")}
                        </NavLink>
                        <NavLink
                            onClick={() => handleDropdownSelection()}
                            to="/bets"
                            className="navbar-link fs-large"
                        >
                            {t("bets")}
                        </NavLink>
                        <NavLink
                            onClick={() => handleDropdownSelection()}
                            to="/ranking"
                            className="navbar-link fs-large"
                        >
                            {t("ranking")}
                        </NavLink>
                        <NavDropdown
                            title={t(navTitle)}
                            id="basic-nav-dropdown"
                            className={"fs-large " + dropdownSelected}
                            style={{ paddingX: "10px", paddingY: "10px" }}
                        >
                            <NavDropdown.Item
                                onClick={() => handleDropdownSelection()}
                                as={NavLink}
                                to="/notifications"
                                className="navbar-link fs-large"
                            >
                                {t("notifications")}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => handleDropdownSelection()}
                                as={NavLink}
                                to="/pointsDistribution"
                                className="navbar-link fs-large"
                            >
                                {t("pointsDistribution")}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                onClick={() => handleDropdownSelection()}
                                as={NavLink}
                                to="/tournamentTree"
                                className="navbar-link fs-large"
                            >
                                {t("tournamentTree")}
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item as={NavLink} to="/topScorer" className="navbar-link fs-large">{t("scorerList")}</NavDropdown.Item> */}
                            {/* <NavDropdown.Item as={NavLink} to="/about" className="navbar-link fs-large">{t("aboutUs")}</NavDropdown.Item> */}
                            <NavDropdown.Item
                                onClick={() => handleDropdownSelection()}
                                as={NavLink}
                                to="/contact"
                                className="navbar-link fs-large"
                            >
                                {t("contact")}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => handleDropdownSelection()}
                                as={NavLink}
                                to="/FAQ"
                                className="navbar-link fs-large"
                            >
                                {t("FAQ")}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <form id="languagesSelection" name="languagesSelection">
                        <label>
                            <input
                                type="radio"
                                name="language"
                                value="es"
                                checked={language === "es"}
                                onChange={() => changeLanguage("es")}
                            />
                            <img
                                className="languageFlag"
                                src="/images/languageFlags/es.png"
                                alt="ES"
                                title="ES"
                                height="30px"
                            />
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="language"
                                value="de"
                                checked={language === "de"}
                                onChange={() => changeLanguage("de")}
                            />
                            <img
                                className="languageFlag"
                                src="/images/languageFlags/de.png"
                                alt="DE"
                                title="DE"
                                height="30px"
                            />
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="language"
                                value="en"
                                checked={language === "en"}
                                onChange={() => changeLanguage("en")}
                            />
                            <img
                                className="languageFlag"
                                src="/images/languageFlags/en.png"
                                alt="EN"
                                title="EN"
                                height="30px"
                            />
                        </label>
                    </form>
                    <Nav>
                        <Navbar.Brand href="https://www.uefa.com/euro2024/" target="_blank">
                            <img
                                id="navbar__logo-championship"
                                className={logoClass}
                                src="/images/Logo_EM.png"
                                alt={t("logo_alt")}
                            />
                        </Navbar.Brand>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarMain;
