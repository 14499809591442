import * as util from "../../utils/utils";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

function RoundPass({ results, bets }) {
    const { t } = useTranslation("translation");

    if (!results.additionals.roundPass) {
        return null;
    }

    results.additionals.roundPass.forEach((res, indexResult) => {
        bets.forEach((bet, indexBet) => {
            if (bet.team === res.team.tla) {
                util.swapArrayPosition(bets, indexBet, indexResult);
            }
        });
    });

    const roundpassTable = results.additionals.roundPass.map((roundPass, index) => (
        <tr key={index}>
            <td id="roundpass__description">
                {t("bets.roundPass.qualifier")} {index + 1}
            </td>
            <td id="roundpass__teamname">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(roundPass.team.tla)}
                    alt={roundPass.team.name}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(roundPass.team.tla))}</span>
            </td>
            <td id="roundpass__bet">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(bets[index].team)}
                    title={bets[index].team}
                    alt={bets[index].team}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(bets[index].team))}</span>
            </td>
            <td id="roundpass__points" className="align-center">
                {bets[index].points}
            </td>
        </tr>
    ));

    return (
        <Container>
            <Table>
                <thead>
                    <tr className="no-border" key={v4()}>
                        <th id="roundpass__description" />
                        <th id="roundpass__teamname">{t("matches.header.result")}</th>
                        <th id="roundpass__bet">{t("matches.header.bet")}</th>
                        <th id="roundpass__points" className="align-center">
                            {t("matches.header.points")}
                        </th>
                    </tr>
                </thead>
                <tbody>{roundpassTable}</tbody>
            </Table>
        </Container>
    );
}

export default RoundPass;
