import Card from "react-bootstrap/Card";
import DonationButton from "../buttons/DonationButton";

import { useTranslation } from "react-i18next";

function DonationCard() {
    const { t } = useTranslation("translation");

    return (
        <Card style={{ marginTop: "1rem", marginBottom: "2rem" }} bg="avisporra">
            <Card.Header as="h5" style={{ color: "#003566" }}>
                <img
                    src="/images/icons/spende.png"
                    alt="Search"
                    height={"25px"}
                    style={{ marginBottom: "0px", marginRight: "10px", verticalAlign: "bottom" }}
                />{" "}
                {t("home.donations.header")}
            </Card.Header>
            <Card.Body>
                {/* <Card.Title>{t("home.donations.header").toUpperCase()}</Card.Title> */}
                <Card.Text>
                    {t("home.donations.text.p1")}
                    <br />
                    <br />
                    {t("home.donations.text.p2")}
                </Card.Text>
                <DonationButton />
            </Card.Body>
        </Card>
    );
}

export default DonationCard;
