import React from "react";
import "../css/loading.css";

import Container from "react-bootstrap/Container";

export default function Loading() {
    return (
        <Container className="contentContainer">
            <div className="loading-screen">
                <div className="loading-spinner"></div>
            </div>
        </Container>
    );
}
