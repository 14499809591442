import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import AccordionFAQ from "../components/AccordionFAQ";
import Legal from "../components/Legal";

function FAQ() {
    const { t } = useTranslation("translation");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    return (
        <Container className="pageContainer">
            <h2 id="pageHeaderText">{t("pages.pageTitle.FAQ")}</h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            <AccordionFAQ />
            <Legal />
        </Container>
    );
}

export default FAQ;
