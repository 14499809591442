import { mediaMaxSize } from "../constants";

export default function getClassRoundPass(match, side, screenType) {
    if (window.innerWidth > mediaMaxSize.laptop) {
        switch (side) {
            case "home":
                if (match.stage !== "GROUP_STAGE" && match.score.winner === "HOME_TEAM") {
                    return "team-block bold";
                } else {
                    return "team-block";
                }
            case "away":
                if (screenType === "desktop") {
                    if (match.stage !== "GROUP_STAGE" && match.score.winner === "AWAY_TEAM") {
                        return "team-block bold";
                    } else {
                        return "team-block";
                    }
                } else {
                    return "no-display";
                }
            default:
                return "";
        }
    } else {
        switch (side) {
            case "home":
                if (match.stage !== "GROUP_STAGE" && match.score.winner === "HOME_TEAM") {
                    return "team-block bold";
                } else {
                    return "team-block";
                }
            case "away":
                if (screenType === "mobile") {
                    if (match.stage !== "GROUP_STAGE" && match.score.winner === "AWAY_TEAM") {
                        return "team-block bold";
                    } else {
                        return "team-block";
                    }
                } else {
                    return "no-display";
                }
            default:
                return "";
        }
    }
}
