import { useTranslation } from "react-i18next";

function Legal() {

    const { t } = useTranslation();

    return (
        <div style={{ fontSize: "10px", lineHeight: "12px", paddingTop: "1rem" }}>
            <p style={{ color: "grey" }}>
                {t("legal")}
            </p>
        </div>
    );
}

export default Legal;
