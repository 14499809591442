import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../css/components/buttons/donationButton.css";

function DonationButton() {
    const { t } = useTranslation("translation");

    const paypalURL = process.env.REACT_APP_PAYPAL_URL;

    return (
        <div className="donationContainer">
            <Link to={paypalURL} target="_blank">
                <button className="donationButton">
                    <img
                        src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg"
                        alt="PayPal short logo (PP)"
                        className="imgInDonationButton"
                    />
                    {t("home.donations.button")} PayPal
                </button>
            </Link>
        </div>
    );
}

export default DonationButton;
