import Accordion from "react-bootstrap/Accordion";
import AccordionFAQItem from "./AccordionFAQItem";

function AccordionFAQ() {
    return (
        <Accordion>
            <AccordionFAQItem questionNumber="1" i18nPrefix="faq.faq01" />
            <AccordionFAQItem questionNumber="2" i18nPrefix="faq.faq02" />
            <AccordionFAQItem questionNumber="3" i18nPrefix="faq.faq03" />
            <AccordionFAQItem questionNumber="4" i18nPrefix="faq.faq04" />
            <AccordionFAQItem questionNumber="5" i18nPrefix="faq.faq05" />
            <AccordionFAQItem questionNumber="6" i18nPrefix="faq.faq06" />
            <AccordionFAQItem questionNumber="7" i18nPrefix="faq.faq07" />
            <AccordionFAQItem questionNumber="8" i18nPrefix="faq.faq08" />
            <AccordionFAQItem questionNumber="9" i18nPrefix="faq.faq09" />
            <AccordionFAQItem questionNumber="10" i18nPrefix="faq.faq10" />
            <AccordionFAQItem questionNumber="11" i18nPrefix="faq.faq11" />
        </Accordion>
    );
}

export default AccordionFAQ;
