import * as util from "../../utils/utils";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

function TournamentClassificationTable({ results, bets }) {
    const { t } = useTranslation("translation");

    if (!results.additionals.tournamentClassification || !bets) {
        return null;
    }

    const tournamentClassificationTable = results.additionals.tournamentClassification.map(
        (key, index) => (
            <tr key={index}>
                <td id="tournament-classification__description">
                    {t(`bets.tournamentClassification.${key.position.toString().toLowerCase()}`)}
                </td>
                <td id="tournament-classification__teamname">
                    <img
                        className="flag--left-aligned"
                        src={util.mapFlag(key.team.tla)}
                        alt={key.team.name}
                        onError={util.mapDummyFlag}
                    />
                    <span className="team-block">{t(util.mapTeamName(key.team.tla))}</span>
                </td>
                <td id="tournament-classification__bet">
                    <img
                        className="flag--left-aligned"
                        src={util.mapFlag(bets[index].team)}
                        alt={bets[index].team}
                        onError={util.mapDummyFlag}
                    />
                    <span className="team-block">{t(util.mapTeamName(bets[index].team))}</span>
                </td>
                <td id="tournament-classification__points" className="align-center">
                    {bets[index].points}
                </td>
            </tr>
        )
    );

    return (
        <Container>
            <Table>
                <thead>
                    <tr className="no-border" key={v4()}>
                        <th id="tournament-classification__description" />
                        <th id="tournament-classification__teamname">
                            {t("matches.header.result")}
                        </th>
                        <th id="tournament-classification__bet">{t("matches.header.bet")}</th>
                        <th id="tournament-classification__points" className="align-center">
                            {t("matches.header.points")}
                        </th>
                    </tr>
                </thead>
                <tbody>{tournamentClassificationTable}</tbody>
            </Table>
        </Container>
    );
}

export default TournamentClassificationTable;
