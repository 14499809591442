import i18n from "../services/i18n";

export default function convertDate(date) {
    const getUserLanguage = () => window.navigator.userLanguage || window.navigator.language;
    let language = i18n.language ? i18n.language : getUserLanguage();

    if (language !== "en" && language !== "de" && language !== "es") {
        language = getUserLanguage();
    }

    return (
        new Date(date).toLocaleDateString(language, {
            year: "numeric",
            month: "long",
            day: "numeric"
        }) +
        " - " +
        new Date(date).toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" })
    );
}
