import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import * as util from "../utils/utils";
import * as constant from "../constants";

import "../css/tournamentTree.css";

import AnimatedScore from "../components/AnimatedScore";

function TournamentTree(props) {
    const results = props.results;

    const { t } = useTranslation("translation");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    if (results.length === 0 || (results.length > 0 && results[0].matches.length === 0)) {
        return (
            <Spinner
                animation="border"
                variant="warning"
                style={{ display: "block", margin: "auto", marginTop: "20em" }}
            />
        );
    }

    return (
        <Container className="pageContainer">
            <h2 id="pageHeaderText">{t("pages.pageTitle.tournamentTree")}</h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            <div className="stages-wrapper">
                <div className="stage round-of-sixteen">
                    <div className="stage-row">
                        {renderStage(results, constant.stage.roundOf16)}
                    </div>
                </div>
                <div className="stage quarter-final">
                    <div className="stage-row">
                        {renderStage(results, constant.stage.quarterFinals)}
                    </div>
                </div>
                <div className="stage semi-final">
                    <div className="stage-row">
                        {renderStage(results, constant.stage.semiFinals)}
                    </div>
                </div>
                <div className="stage final">
                    <div className="stage-row">{renderStage(results, constant.stage.final)}</div>
                </div>
            </div>
        </Container>
    );

    function renderStage(results, stage) {
        const stageMatches = getStageMatches(results, stage);
        return stageMatches.map((match, index) => (
            <div className="stage-panel" key={match.id}>
                <div className="fixture-panel">
                    <div className="panel panel-default tree-panel">
                        <div className="panel-heading">
                            <span className="stage-label-short">
                                {getStageNameShort(match.stage)} {getMatchNo(match.stage, index)}
                            </span>
                            <span className="stage-label">
                                {getStageNameLong(match.stage)} {getMatchNo(match.stage, index)}
                            </span>
                            <hr style={{ margin: "3px" }} />
                            <div className="gameDate">
                                <span className="weekday-label">
                                    {getWeekDay(match.utcDate.substring(0, 10))}
                                </span>
                                <span className="day-label">
                                    {" "}
                                    {/* {util.convertDate(match.utcDate)} */}
                                    {match.utcDate.substring(8, 10) +
                                        "." +
                                        match.utcDate.substring(5, 7)}
                                </span>
                            </div>
                            <div className="gameTime">
                                {/* {match.utcDate.substring(11, 16)} */}
                                {util.convertDate(match.utcDate).split("-")[1]}
                            </div>

                        </div>
                        <div className="panel-body" id={"tree" + match.id}>
                            <div className="team-wrapper team-h">
                                <div className="team-name">
                                    <span>{t(util.mapTeamName(match.homeTeam.tla))}</span>
                                </div>
                                <img
                                    src={util.mapFlag(match.homeTeam.tla)}
                                    alt={match.homeTeam.name}
                                    onError={util.mapDummyFlag}
                                />
                            </div>
                            <div className="team-wrapper team-v">
                                <img
                                    src={util.mapFlag(match.awayTeam.tla)}
                                    alt={match.awayTeam.name}
                                    onError={util.mapDummyFlag}
                                />
                                <div className="team-name">
                                    <span>{t(util.mapTeamName(match.awayTeam.tla))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="panel-score fs-large">
                            <span className={util.getClassLiveResult(match.status)}>
                                <AnimatedScore score={util.mapScore(match.score)} />
                            </span>
                        </div>
                    </div>
                </div>
                {renderFooter(match.stage)}
            </div>
        ));

        function getStageMatches(results, stage) {
            switch (stage) {
                case constant.stage.roundOf16:
                    return [
                        results[6].matches[2],
                        results[6].matches[0],
                        results[6].matches[4],
                        results[6].matches[5],
                        results[6].matches[6],
                        results[6].matches[7],
                        results[6].matches[3],
                        results[6].matches[1]
                    ];
                case constant.stage.quarterFinals:
                    return results[7].matches;
                case constant.stage.semiFinals:
                    return results[8].matches;
                case constant.stage.final:
                    return results[9].matches;
                default:
            }
        }

        function getStageNameShort(stage) {
            switch (stage) {
                case constant.stage.roundOf16:
                    return t("stages.short.r16");
                case constant.stage.quarterFinals:
                    return t("stages.short.qf");
                case constant.stage.semiFinals:
                    return t("stages.long.sf");
                case constant.stage.final:
                    return t("stages.long.final");
                default:
            }
        }

        function getStageNameLong(stage) {
            switch (stage) {
                case constant.stage.roundOf16:
                    return t("stages.long.r16");
                case constant.stage.quarterFinals:
                    return t("stages.long.qf");
                case constant.stage.semiFinals:
                    return t("stages.long.sf");
                case constant.stage.final:
                    return t("stages.long.final");
                default:
            }
        }

        function getMatchNo(stage, index) {
            switch (stage) {
                case constant.stage.roundOf16:
                    switch (index) {
                        case 0:
                            return 3;
                        case 1:
                            return 1;
                        case 2:
                            return 5;
                        case 3:
                            return 6;
                        case 4:
                            return 7;
                        case 5:
                            return 8;
                        case 6:
                            return 4;
                        case 7:
                            return 2;
                        default:
                            return "";
                    }
                case constant.stage.quarterFinals:
                    switch (index) {
                        case 0:
                            return 1;
                        case 1:
                            return 2;
                        case 2:
                            return 4;
                        case 3:
                            return 3;
                        default:
                            return "";
                    }
                case constant.stage.final:
                    return "";
                default:
                    return index + 1;
            }
        }

        function getWeekDay(date) {
            const weekdayNumber = new Date(date.substring(0, 10)).getDay();
            switch (weekdayNumber) {
                case 0:
                    return t("general.dayOfTheWeek.Sunday");
                case 1:
                    return t("general.dayOfTheWeek.Monday");
                case 2:
                    return t("general.dayOfTheWeek.Tuesday");
                case 3:
                    return t("general.dayOfTheWeek.Wednesday");
                case 4:
                    return t("general.dayOfTheWeek.Thursday");
                case 5:
                    return t("general.dayOfTheWeek.Friday");
                case 6:
                    return t("general.dayOfTheWeek.Saturday");
                default:
                    return "";
            }
        }

        function renderFooter(stage) {
            if (stage !== constant.stage.final)
                return (
                    <div className="stage-panel-footer">
                        <div className="gap">
                            <div className="gap-up">
                                <div className="gap-up-left"></div>
                                <div className="gap-up-right"></div>
                            </div>
                            <div className="gap-down"></div>
                        </div>
                    </div>
                );
        }
    }
}

export default TournamentTree;
