import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Typeahead } from "react-bootstrap-typeahead";

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

import * as util from "../utils/utils";

import { v4 } from "uuid";

import * as constant from "../constants";

function Ranking(props) {
    const bets = props.bets;
    const results = props.results;

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    const [storageItem, setStorageItem] = useState(() =>
        JSON.parse(localStorage.getItem("favourites") || "[]")
    );

    const favourites = localStorage.getItem("favourites")
        ? JSON.parse(localStorage.getItem("favourites"))
        : [];

    if (results.length === 0 || bets.length === 0) {
        return (
            <Spinner
                animation="border"
                variant="warning"
                style={{ display: "block", margin: "auto", marginTop: "20em" }}
            />
        );
    }

    let rankings = getRanking(bets);
    let rankingsFav = getFavouritesRanking(rankings, favourites);
    let bettorNames = getBettorNames(rankings);

    function isInitalState() {
        let distributedPoints = 0;
        rankings.forEach((bettor) => {
            distributedPoints += bettor.points.total;
        });
        return !distributedPoints ? true : false;
    }

    function getRanking(bets) {
        let ranking = [];
        bets.forEach((bettor) => {
            ranking.push({
                bettor: bettor.nickname,
                points: {
                    groupA: calcPointsByStage(
                        bettor,
                        constant.stage.groupStage,
                        constant.stage.groups.groupA
                    ),
                    groupB: calcPointsByStage(
                        bettor,
                        constant.stage.groupStage,
                        constant.stage.groups.groupB
                    ),
                    groupC: calcPointsByStage(
                        bettor,
                        constant.stage.groupStage,
                        constant.stage.groups.groupC
                    ),
                    groupD: calcPointsByStage(
                        bettor,
                        constant.stage.groupStage,
                        constant.stage.groups.groupD
                    ),
                    groupE: calcPointsByStage(
                        bettor,
                        constant.stage.groupStage,
                        constant.stage.groups.groupE
                    ),
                    groupF: calcPointsByStage(
                        bettor,
                        constant.stage.groupStage,
                        constant.stage.groups.groupF
                    ),
                    last16: calcPointsByStage(bettor, constant.stage.roundOf16, null),
                    qf: calcPointsByStage(bettor, constant.stage.quarterFinals, null),
                    sf: calcPointsByStage(bettor, constant.stage.semiFinals, null),
                    final: calcPointsByStage(bettor, constant.stage.final, null),
                    total: bettor.points
                }
            });
        });
        ranking.sort((a, b) => a.bettor.localeCompare(b.bettor));
        ranking.sort((a, b) => b.points.total - a.points.total);

        let rank = 0;
        let prevPoints = 0;
        let prevRank = 0;
        ranking.forEach((key, index) => {
            rank += 1;
            if (prevPoints !== key.points.total) {
                key.rank = rank;
            } else {
                key.rank = prevRank;
            }
            prevPoints = key.points.total;
            prevRank = key.rank;
        });
        return ranking;
    }

    function getFavouritesRanking(ranking, favourites) {
        let rankingFav = [];
        ranking.forEach((bettor) => {
            if (favourites.includes(bettor.bettor)) {
                rankingFav.rank = bettor.rank;
                rankingFav.push(bettor);
            }
        });
        rankingFav.sort((a, b) => a.bettor.localeCompare(b.bettor));
        rankingFav.sort((a, b) => b.points.total - a.points.total);
        return rankingFav;
    }

    function getBettorNames(ranking) {
        let bettorNames = [];
        ranking.forEach((bettor) => {
            bettorNames.push(bettor.bettor);
        });
        bettorNames.sort((a, b) => a.localeCompare(b));
        return bettorNames;
    }

    const renderRank = (rank) => {
        if (isInitalState()) return "-";
        if (rank > 3) return rank;
    };

    const handleToggleFavourite = (id) => {
        const isFavourited = storageItem.includes(id);
        if (!isFavourited) {
            const newStorageItem = [...storageItem, id];
            setStorageItem(newStorageItem);
            localStorage.setItem("favourites", JSON.stringify(newStorageItem));
        } else {
            const newStorageItem = storageItem.filter((savedId) => savedId !== id);
            setStorageItem(newStorageItem);
            localStorage.setItem("favourites", JSON.stringify(newStorageItem));
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const row = document.getElementById(event.target.value);
            if (row) {
                row.scrollIntoView({ behavior: "instant", block: "center" });
                row.animate(
                    [
                        {
                            scale: "1",

                        },
                        {
                            scale: "1.10"
                        },
                        {
                            scale: "1"
                        }
                    ],
                    {
                        duration: 500,
                        delay: 250
                    }
                );
            }
        }
    };

    const handleOnChange = (selected) => {
        if (selected[0] !== undefined) {
            const row = document.getElementById(selected[0]);
            if (row) {
                row.scrollIntoView({ behavior: "instant", block: "center" });
                row.animate(
                    [
                        {
                            scale: "1",

                        },
                        {
                            scale: "1.10"
                        },
                        {
                            scale: "1"
                        }
                    ],
                    {
                        duration: 500,
                        delay: 250
                    }
                );
            }
        }
    };

    function renderRankImage(rank) {
        if (isInitalState()) return "";
        switch (rank) {
            case 1:
                return "/images/ranking/Rank1.png";
            case 2:
                return "/images/ranking/Rank2.png";
            case 3:
                return "/images/ranking/Rank3.png";
            default:
                return "";
        }
    }

    function calcPointsByStage(bettor, stage, group) {
        let points = 0;
        bettor.bets.matches.forEach((match) => {
            if (match.stage === stage && match.group === group) {
                points += match.points;
            }
        });
        bettor.bets.additionals.forEach((key) => {
            if (key.stage === constant.stage.groupStage) {
                key.groups.forEach((groupKey) => {
                    if (groupKey.group === group) {
                        points += groupKey.points;
                    }
                });
            } else {
                if (key.stage === stage) {
                    points += key.points;
                }
            }
        });
        return points;
    }

    const rankingTable = rankings.map((key) => (
        <tr id={key.bettor} key={key.bettor}>
            <td className="align-middle text-center">
                <img
                    src={renderRankImage(key.rank)}
                    height="40"
                    alt={key.rank}
                    onError={(i) => (i.target.style.display = "none")}
                />
                {renderRank(key.rank)}
            </td>
            <td id="rankingBettorName" className="align-middle" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                <Link className="link" to={"/bets"} state={key.bettor}>
                    {/* {window.innerWidth <= constant.mediaMaxSize.mobile ? (window.innerWidth <= constant.mediaMaxSize.smallMobile ? util.truncate(key.bettor, 15) : util.truncate(key.bettor, 20)) : key.bettor} */}
                    {key.bettor}
                </Link>
            </td>
            <td className="align-middle text-center">{key.points.total}</td>
            <td>
                <Button
                    id="favButton"
                    variant="text"
                    onClick={() => handleToggleFavourite(key.bettor)}
                    title="Favorite"
                >
                    {storageItem.includes(key.bettor) ? (
                        <AiFillHeart color="orange" />
                    ) : (
                        <AiOutlineHeart color="error" />
                    )}
                </Button>
                {""}
            </td>
            <td
                className="align-middle text-center pointsDetails"
                style={{ borderLeft: "1px dashed #9999" }}
            >
                {key.points.groupA}
            </td>
            <td className="align-middle text-center pointsDetails">{key.points.groupB}</td>
            <td className="align-middle text-center pointsDetails">{key.points.groupC}</td>
            <td className="align-middle text-center pointsDetails">{key.points.groupD}</td>
            <td className="align-middle text-center pointsDetails">{key.points.groupE}</td>
            <td
                className="align-middle text-center pointsDetails"
                style={{ borderRight: "1px dashed #9999" }}
            >
                {key.points.groupF}
            </td>
            <td className="align-middle text-center pointsDetails">{key.points.last16}</td>
            <td className="align-middle text-center pointsDetails">{key.points.qf}</td>
            <td className="align-middle text-center pointsDetails">{key.points.sf}</td>
            <td className="align-middle text-center pointsDetails">{key.points.final}</td>
        </tr>
    ));

    const rankingFavTable = rankingsFav.map((key) => (
        <tr id={key.bettor} key={key.bettor}>
            <td className="align-middle text-center">
                <img
                    src={renderRankImage(key.rank)}
                    height="40"
                    alt={key.rank}
                    onError={(i) => (i.target.style.display = "none")}
                />
                {renderRank(key.rank)}
            </td>
            <td className="align-middle">
                <Link className="link" to={"/bets"} state={key.bettor}>
                    {window.innerWidth <= constant.mediaMaxSize.mobile ? (window.innerWidth <= constant.mediaMaxSize.smallMobile ? util.truncate(key.bettor, 15) : util.truncate(key.bettor, 20)) : key.bettor}
                </Link>
            </td>
            <td className="align-middle text-center">{key.points.total}</td>
            <td>
                <Button
                    id="favButton"
                    variant="text"
                    onClick={() => handleToggleFavourite(key.bettor)}
                    title="Favorite"
                >
                    {storageItem.includes(key.bettor) ? (
                        <AiFillHeart color="orange" />
                    ) : (
                        <AiOutlineHeart color="error" />
                    )}
                </Button>
                {""}
            </td>
            <td
                className="align-middle text-center pointsDetails"
                style={{ borderLeft: "1px dashed #9999" }}
            >
                {key.points.groupA}
            </td>
            <td className="align-middle text-center pointsDetails">{key.points.groupB}</td>
            <td className="align-middle text-center pointsDetails">{key.points.groupC}</td>
            <td className="align-middle text-center pointsDetails">{key.points.groupD}</td>
            <td className="align-middle text-center pointsDetails">{key.points.groupE}</td>
            <td
                className="align-middle text-center pointsDetails"
                style={{ borderRight: "1px dashed #9999" }}
            >
                {key.points.groupF}
            </td>
            <td className="align-middle text-center pointsDetails">{key.points.last16}</td>
            <td className="align-middle text-center pointsDetails">{key.points.qf}</td>
            <td className="align-middle text-center pointsDetails">{key.points.sf}</td>
            <td className="align-middle text-center pointsDetails">{key.points.final}</td>
        </tr>
    ));

    return (
        <Container className="pageContainer">
            <h2 id="pageHeaderText">{t("pages.pageTitle.ranking")}</h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            <Tab.Container defaultActiveKey="/ranking">
                <Nav justify fill variant="pills" id="groups2">
                    <Nav.Item>
                        <Nav.Link eventKey="/ranking">{t("ranking.rankings")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/rankingFav">{t("ranking.favourites")}</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="/ranking">
                        <div id="rankingSearch">
                            <h3>
                                <img
                                    src="/images/icons/search.png"
                                    alt="Search"
                                    height={"50px"}
                                    style={{
                                        marginBottom: "2px",
                                        verticalAlign: "bottom"
                                    }}
                                />{" "}
                                {t("general.searchBettor")}
                            </h3>
                            <Typeahead
                                id="rankingSearchBar"
                                options={bettorNames}
                                placeholder={t("ranking.searchPlaceholder")}
                                onKeyDown={handleKeyDown}
                                onChange={handleOnChange}
                            />
                        </div>
                        <Container className="rankingContainer">
                            <Table id="rankingTable" striped={true}>
                                <thead>
                                    <tr className="no-border" key={v4()}>
                                        <th className="align-middle text-center">
                                            {t("ranking.rank")}
                                        </th>
                                        <th>{t("ranking.bettor")}</th>
                                        <th className="align-middle text-center">
                                            {t("ranking.points")}
                                        </th>
                                        <th />
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupA")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupB")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupC")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupD")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupE")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupF")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.r16")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.qf")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.sf")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.final")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{rankingTable}</tbody>
                            </Table>
                        </Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="/rankingFav">
                        <Container className="rankingContainer">
                            <Table striped={true}>
                                <thead>
                                    <tr className="no-border" key={v4()}>
                                        <th className="align-middle text-center">
                                            {t("ranking.rank")}
                                        </th>
                                        <th>{t("ranking.bettor")}</th>
                                        <th className="align-middle text-center">
                                            {t("ranking.points")}
                                        </th>
                                        <th />
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupA")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupB")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupC")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupD")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupE")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.groups.groupF")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.r16")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.qf")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.sf")}
                                        </th>
                                        <th className="align-middle text-center pointsDetails">
                                            {t("stages.short.final")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{rankingFavTable}</tbody>
                            </Table>
                        </Container>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    );
}

export default Ranking;
