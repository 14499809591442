import { mediaMaxSize } from "../constants";

export default function teamName(team) {
    if (team === null || !team) {
        return "-";
    } else {
        if (window.innerWidth < mediaMaxSize.tablet) {
            return team;
        } else {
            return "teamnames." + team;
        }
    }
}
