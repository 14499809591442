import { useEffect } from "react";
import i18n from "../services/i18n";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import { useTranslation } from "react-i18next";

function Notifications() {
    const { t } = useTranslation("translation");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    const language = `${i18n.language}-${i18n.language.toUpperCase()}`;

    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    };

    return (
        <Container className="pageContainer">
            <h2 id="pageHeaderText">{t("pages.pageTitle.notifications")}</h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            <Card bg="info" style={{ marginTop: "1rem" }}>
                <Card.Header>{t("notifications.messageType.info")}</Card.Header>
                <Card.Body>
                    <Card.Title>{t("notifications.message06.title")}</Card.Title>
                    <Card.Text>{t("notifications.message06.description")}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {new Date("2024-06-26").toLocaleDateString(language, options)}
                </Card.Footer>
            </Card>
            <Card bg="info" style={{ marginTop: "1rem" }}>
                <Card.Header>{t("notifications.messageType.info")}</Card.Header>
                <Card.Body>
                    <Card.Title>{t("notifications.message05.title")}</Card.Title>
                    <Card.Text>{t("notifications.message05.description")}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {new Date("2024-06-24").toLocaleDateString(language, options)}
                </Card.Footer>
            </Card>
            <Card bg="info" style={{ marginTop: "1rem" }}>
                <Card.Header>{t("notifications.messageType.info")}</Card.Header>
                <Card.Body>
                    <Card.Title>{t("notifications.message04.title")}</Card.Title>
                    <Card.Text>{t("notifications.message04.description")}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {new Date("2024-06-15").toLocaleDateString(language, options)}
                </Card.Footer>
            </Card>
            <Card bg="info" style={{ marginTop: "1rem" }}>
                <Card.Header>{t("notifications.messageType.info")}</Card.Header>
                <Card.Body>
                    <Card.Title>{t("notifications.message03.title")}</Card.Title>
                    <Card.Text>{t("notifications.message03.description")}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {new Date("2024-06-15").toLocaleDateString(language, options)}
                </Card.Footer>
            </Card>
            <Card bg="info" style={{ marginTop: "1rem" }}>
                <Card.Header>{t("notifications.messageType.info")}</Card.Header>
                <Card.Body>
                    <Card.Title>{t("notifications.message02.title")}</Card.Title>
                    <Card.Text>{t("notifications.message02.description")}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {new Date("2024-06-14").toLocaleDateString(language, options)}
                </Card.Footer>
            </Card>
            <Card bg="info" style={{ marginTop: "1rem" }}>
                <Card.Header>{t("notifications.messageType.info")}</Card.Header>
                <Card.Body>
                    <Card.Title>{t("notifications.message01.title")}</Card.Title>
                    <Card.Text>{t("notifications.message01.description")}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {new Date("2024-05-03").toLocaleDateString(language, options)}
                </Card.Footer>
            </Card>
        </Container>
    );
}

export default Notifications;
