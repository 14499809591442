import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "../hooks/useCountdown";
import Card from "react-bootstrap/Card";

import { useTranslation } from "react-i18next";

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    const { t } = useTranslation("translation", { keyPrefix: "countdown" });

    return (
        <Card
            id="cardCountdown"
            style={{ marginBottom: "-10px", border: 0, paddingTop: "0.5em" }}
            border="secondary"
        >
            <Card.Body className="show-counter">
                <DateTimeDisplay value={days} type={t("days")} />
                <DateTimeDisplay value={hours} type={t("hours")} />
                <DateTimeDisplay value={minutes} type={t("minutes")} />
                <DateTimeDisplay value={seconds} type={t("seconds")} />
            </Card.Body>
        </Card>
    );
};

const CountdownTimer = ({ targetDate }) => {

    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ShowCounter days={0} hours={0} minutes={0} seconds={0} />;
    } else {
        return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
    }
};

export default CountdownTimer;
