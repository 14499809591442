const stage = {
    groupStage: "GROUP_STAGE",
    groups: {
        groupA: "GROUP_A",
        groupB: "GROUP_B",
        groupC: "GROUP_C",
        groupD: "GROUP_D",
        groupE: "GROUP_E",
        groupF: "GROUP_F"
    },
    roundOf16: "LAST_16",
    quarterFinals: "QUARTER_FINALS",
    semiFinals: "SEMI_FINALS",
    final: "FINAL"
};

const matchStatus = {
    in_play: "IN_PLAY",
    paused: "PAUSED"
};

const mediaMaxSize = {
    smallMobile: 400,
    mobile: 480,
    tablet: 768,
    laptop: 990,
    desktop: 1200
};

export { stage, matchStatus, mediaMaxSize };
