import * as util from "../../utils/utils";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import "../../css/components/table/other.css";

function Other({ results, bets }) {
    function renderDefaultImagePlayer() {
        return "/images/player.png";
    }

    function mapPlayerName(name) {
        if (name === null) {
            return "-";
        } else {
            return name;
        }
    }

    const { t } = useTranslation("translation");

    if (!results.additionals.other || !bets) {
        return null;
    }

    const otherTable = results.additionals.other.map((key, index) => (
        <tr key={index}>
            <td id="other__description">{t(`bets.others.${util.camelize(key.category)}`)}</td>
            <td id="other__teamname">
                <img
                    className="flag--left-aligned"
                    src={renderDefaultImagePlayer()}
                    alt={key.player}
                />
                <div className="team-block" style={{ verticalAlign: "middle" }}>
                    {
                        key.players.map((player, index) => (
                            <span key={index}>{mapPlayerName(player)}<br /></span>
                        ))
                    }
                </div>
            </td>
            <td id="other__bet">
                <img
                    className="flag--left-aligned"
                    src={renderDefaultImagePlayer()}
                    alt={bets[index].player}
                />
                <span className="team-block">{mapPlayerName(bets[index].player)}</span>
            </td>
            <td id="other__points" className="align-center">
                {bets[index].points}
            </td>
        </tr>
    ));

    return (
        <Container>
            <Table>
                <thead>
                    <tr className="no-border" key={v4()}>
                        <th id="other__description" />
                        <th id="other__teamname">{t("matches.header.result")}</th>
                        <th id="other__bet">{t("matches.header.bet")}</th>
                        <th id="other__points" className="align-center">
                            {t("matches.header.points")}
                        </th>
                    </tr>
                </thead>
                <tbody>{otherTable}</tbody>
            </Table>
        </Container>
    );
}

export default Other;
