import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { format } from "date-fns";

// import i18n from "../services/i18n";

import MatchesFeatured from "../components/tables/MatchesFeatured";
import DonationCard from "../components/cards/DonationCard";
import PayoutsCard from "../components/cards/PayoutsCard";
import ThankYouCard from "../components/cards/ThankYouCard";
import DeadlineCard from "../components/cards/DeadlineCard";
// import InfoCard from "../components/cards/InfoCard";
import CountdownTimer from "../components/CountdownTimer";

import competitionData from "../data/competition.json";
import SpeedometerCard from "../components/cards/SpeedometerCard";

const { fetchGeoLocation } = require("../api/fetchGeoLocation");

function Home(props) {
    const results = props.results;
    const bets = props.bets;
    const updateTime = props.updateTime;

    const [show, setShow] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [validationFindings, setValidationFindings] = useState([]);
    const [submissionError, setSubmissionError] = useState(false);
    const [bettorName, setBettorName] = useState("");
    const [bettorMail, setBettorMail] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    const handleClose = () => {
        setShow(false);
        setValidationFindings([]);
    };

    const handleShow = () => setShow(true);

    const handleCloseConfirmation = () => setShowResponse(false);

    const handleFileUpload = async (e) => {
        if (!e) return;

        const formData = new FormData();

        formData.append("file", e.target.files[0]);
        formData.append("location", await fetchGeoLocation());

        await fetch(process.env.REACT_APP_BASE_URL + "validate", {
            method: "POST",
            body: formData
        })
            .then((response) => {
                setSubmissionError(false);
                return response.json();
            })
            .then((responseJSON) => {
                if (responseJSON.nickname) {
                    setValidationFindings([]);
                    setBettorName(responseJSON.nickname);
                    setBettorMail(responseJSON.mail);
                    setShow(false);
                    setShowResponse(true);
                } else {
                    setValidationFindings(responseJSON);
                }
            })
            .catch((error) => {
                setValidationFindings([]);
                setSubmissionError(true);
                setShow(false);
                setShowResponse(true);
                console.log(error);
            });
    };

    const tournamentStartDate = competitionData.period.startDate;
    const tournamentEndDate = competitionData.period.endDate;

    let todaysDate = format(new Date(), "yMMdd"); // Production: Today
    // let todaysDate = format(new Date("2024-08-15"), "yMMdd"); // TEST
    switch (process.env.REACT_APP_DATASET) {
        case "TEST.BEFORE":
            todaysDate = format(new Date("2024-05-15"), "yMMdd"); // before Tournament
            break;
        case "TEST.RUNNING":
            todaysDate = format(new Date("2024-06-15"), "yMMdd"); // running Tournament
            break;
        case "TEST.AFTER":
            todaysDate = format(new Date("2024-07-15"), "yMMdd"); // after Tournament
            break;
        default:
    }

    if (todaysDate < tournamentStartDate) {
        return renderBeforeTournament();
    } else if (todaysDate >= tournamentStartDate && todaysDate <= tournamentEndDate) {
        return renderRunningTournament();
    } else {
        return renderAfterTournament();
    }

    function renderBeforeTournament() {
        return (
            <Container className="pageContainer" style={{ background: "white" }}>
                <h2 id="pageHeaderText" style={{ color: "#001D3D" }}>
                    {t("pages.pageTitle.home.before")}
                </h2>
                <hr className="dashed" style={{ marginBottom: "2rem" }} />
                <DeadlineCard />
                <CountdownTimer targetDate={new Date(2024, 5, 14, 21, 0)}></CountdownTimer>
                <img width="100%" src="/images/UEFA_B.png" alt="" style={{ borderRadius: "5px" }} />
                <SpeedometerCard bets={bets} />
                <Card bg="avisporra" style={{ margin: "auto", marginTop: "1rem" }}>
                    <Card.Header as="h5" style={{ color: "#003566" }}>
                        <img
                            src="/images/icons/fragezeichen.png"
                            alt="Search"
                            height={"25px"}
                            style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                                verticalAlign: "bottom"
                            }}
                        />{" "}
                        {t("home.preTournament.howTo.title.header")}
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>{t("home.preTournament.howTo.title.text")}</Card.Text>
                        <Card.Subtitle className="mb-2" style={{ marginTop: "1rem" }}>
                            {t("home.preTournament.howTo.step01.header").toUpperCase()}
                        </Card.Subtitle>
                        <Card.Text>{t("home.preTournament.howTo.step01.text")}</Card.Text>
                        <Button variant="avisporra" size="md" href={t("filePath")} download>
                            <img
                                src="/images/icons/herunterladen.png"
                                alt="Download"
                                height={"25px"}
                                style={{ marginRight: "5px", verticalAlign: "bottom" }}
                            />
                            {t("home.preTournament.download")}
                        </Button>
                        <Card.Subtitle className="mb-2" style={{ marginTop: "1rem" }}>
                            {t("home.preTournament.howTo.step02.header").toUpperCase()}
                        </Card.Subtitle>
                        <Card.Text>{t("home.preTournament.howTo.step02.text")}</Card.Text>
                        <Card.Subtitle className="mb-2" style={{ marginTop: "1rem" }}>
                            {t("home.preTournament.howTo.step03.header").toUpperCase()}
                        </Card.Subtitle>
                        <Card.Text>{t("home.preTournament.howTo.step03.text")}</Card.Text>
                        <Button variant="avisporra" size="md" onClick={handleShow}>
                            <img
                                src="/images/icons/cloud-upload.png"
                                alt="Upload"
                                height={"25px"}
                                style={{ marginRight: "10px", verticalAlign: "bottom" }}
                            />
                            {t("home.preTournament.uploadButton")}
                        </Button>
                        <Card.Subtitle className="mb-2" style={{ marginTop: "1rem" }}>
                            {t("home.preTournament.howTo.step04.header").toUpperCase()}
                        </Card.Subtitle>
                        <Card.Text>{t("home.preTournament.howTo.step04.text")}</Card.Text>
                        <Card.Subtitle className="mb-2" style={{ marginTop: "1rem" }}>
                            {t("home.preTournament.howTo.step05.header").toUpperCase()}
                        </Card.Subtitle>
                        <Card.Text>{t("home.preTournament.howTo.step05.text")}</Card.Text>
                    </Card.Body>
                </Card>
                <DonationCard />
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("home.preTournament.uploadModalSelection.title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>
                                {t("home.preTournament.uploadModalSelection.selection")}
                            </Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileUpload}
                                lang=""
                            />
                        </Form.Group>
                        {renderValidationFindings(validationFindings)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t("home.preTournament.uploadModalSelection.closeButton")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showResponse}
                    onHide={handleCloseConfirmation}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("home.preTournament.uploadModalResponse.title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{renderResponse()}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseConfirmation}>
                            {t("home.preTournament.uploadModalSelection.closeButton")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }

    function renderRunningTournament() {
        return (
            <Container className="pageContainer">
                <MatchesFeatured results={results} updateTime={updateTime} />
                <SpeedometerCard bets={bets} />
                {/* <InfoCard /> */}
                <DonationCard />
            </Container>
        );
    }

    function renderAfterTournament() {
        return (
            <Container className="pageContainer">
                <h2 id="pageHeaderText">{t("home.after.header")}</h2>
                <hr className="dashed" style={{ marginBottom: "2rem" }} />
                <PayoutsCard bets={bets} />
                <div style={{ marginBottom: "2rem" }}>
                    <img
                        src="/images/winner.jpeg"
                        alt="Winner"
                        width="100%"
                        style={{ borderRadius: "8px" }}
                    />
                </div>
                <ThankYouCard />
                <DonationCard />
            </Container>
        );
    }

    function renderValidationFindings(validationFindings) {
        if (validationFindings.length === 0) return;
        return (
            <div>
                <h5>{t("home.preTournament.uploadModalSelection.validationErrorsHeading")}</h5>
                {validationFindings.map((data, index) => (
                    <li key={index}>
                        {t("home.preTournament.uploadModalSelection." + data[0].toLowerCase()) +
                            data[1]}
                        <br />
                    </li>
                ))}
            </div>
        );
    }

    function renderResponse() {
        if (submissionError) {
            return (
                <div>
                    <h5>{t("home.preTournament.uploadModalResponse.errorHeading")}</h5>
                    <p>{t("home.preTournament.uploadModalResponse.errorText")}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <h5>{t("home.preTournament.uploadModalResponse.successHeading")}</h5>
                    <br />
                    <p>
                        🧑 Name: <b>{bettorName}</b> <br />
                        📧 E-Mail: <b>{bettorMail}</b>
                    </p>
                    <br />
                    <br />
                    <p>{t("home.preTournament.uploadModalResponse.successText")}</p>
                </div>
            );
        }
    }
}

export default Home;
