import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const getUserLanguage = () => window.navigator.userLanguage || window.navigator.language;

const i18nOptions = {
    fallbackLng: "en",
    supportedLngs: ["es", "en", "de"],
    debug: false,
    load: "languageOnly",
    preload: ["es", "en", "de"],
    react: {
        bindI18nStore: "added"
    }
};

i18n.use(HttpBackend).use(LanguageDetector).use(initReactI18next).init(i18nOptions);

if (
    localStorage.getItem("i18nextLng") !== "en" &&
    localStorage.getItem("i18nextLng") !== "es" &&
    localStorage.getItem("i18nextLng") !== "de"
) {
    let language = getUserLanguage().substring(0, 2);
    if (language !== "en" && language !== "es" && language !== "de") {
        localStorage.setItem("i18nextLng", "en");
    } else {
        localStorage.setItem("i18nextLng", language);
    }
}

export default i18n;
