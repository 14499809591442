export default function stageName(match) {
    switch (match.stage) {
        case "GROUP_STAGE":
            return "matches.stages.group" + match.group.slice(-1);
        case "LAST_16":
            return "matches.stages.r16";
        case "QUARTER_FINALS":
            return "matches.stages.qf";
        case "SEMI_FINALS":
            return "matches.stages.sf";
        case "FINAL":
            return "matches.stages.final";
        default:
            return "";
    }
}
