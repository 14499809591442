const fetchGeoLocation = async () => {

    const location = await fetch("https://ipapi.co/json/")
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        })
        .then((responseJson) => {
            return responseJson.country;
        })
        .catch((error) => {
            console.log(error);
        });

    console.log(location);

    return location;

};

exports.fetchGeoLocation = fetchGeoLocation;
