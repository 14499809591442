import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Typeahead } from "react-bootstrap-typeahead";
import Spinner from "react-bootstrap/Spinner";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

import Group from "../components/Group";

function Bets(props) {
    const updateTime = props.updateTime;
    const bets = props.bets;
    const results = props.results;

    const { t } = useTranslation("translation");

    const [bettorName, setUpdated] = useState(
        localStorage.getItem("lastSelectedUser")
            ? JSON.parse(localStorage.getItem("lastSelectedUser"))
            : ""
    );
    const [stage, setSelectedStage] = useState(getSelectedGroup(getLastSelectedTab()));

    const [storageItem, setStorageItem] = useState(() =>
        JSON.parse(localStorage.getItem("favourites") || "[]")
    );

    const locationValue = useLocation().state;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        if (locationValue) {
            setUpdated(locationValue);
        }
    }, [locationValue]);

    const handleToggleFavourite = (id) => {
        const isFavourited = storageItem.includes(id);
        if (!isFavourited) {
            const newStorageItem = [...storageItem, id];
            setStorageItem(newStorageItem);
            localStorage.setItem("favourites", JSON.stringify(newStorageItem));
        } else {
            const newStorageItem = storageItem.filter((savedId) => savedId !== id);
            setStorageItem(newStorageItem);
            localStorage.setItem("favourites", JSON.stringify(newStorageItem));
        }
    };

    if (results.length === 0 || bets.length === 0) {
        return (
            <Spinner
                animation="border"
                variant="warning"
                style={{ display: "block", margin: "auto", marginTop: "20em" }}
            />
        );
    }

    return (
        <Container className="pageContainer">
            <h2 id="pageHeaderText">{t("pages.pageTitle.bets")}</h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            <div>
                <h3>
                    <img
                        src="/images/icons/search.png"
                        alt="Search"
                        height={"50px"}
                        style={{ marginBottom: "2px", verticalAlign: "bottom" }}
                    />{" "}
                    {t("general.searchBettor")}
                </h3>
                <Typeahead
                    id="userSearch"
                    options={getBettorNames(bets)}
                    placeholder={t("general.searchBettorPlaceholder")}
                    defaultInputValue={getDefaultInputValue()}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    onInputChange={handleOnInputChange}
                />
            </div>
            {getBettorPoints(bets, bettorName)}
            {renderGroups()}
        </Container>
    );

    function handleKeyDown(event) {
        if (event.key === "Enter" && event.target.value !== undefined) {
            setUpdated(event.target.value);
            localStorage.setItem("lastSelectedUser", JSON.stringify(event.target.value));
        }
    }

    function handleOnChange(selected) {
        if (selected.length === 0) return;
        setUpdated(selected[0]);
        localStorage.setItem("lastSelectedUser", JSON.stringify(selected[0]));
    }

    function handleOnInputChange(text) {
        if (text === "" || getBettorIdByName(text)) {
            setUpdated(text);
            localStorage.setItem("lastSelectedUser", JSON.stringify(text));
        }
    }

    function getDefaultInputValue() {
        let defaultInputValue = locationValue;
        if (defaultInputValue === null) {
            defaultInputValue = localStorage.getItem("lastSelectedUser")
                ? JSON.parse(localStorage.getItem("lastSelectedUser"))
                : "";
        }
        localStorage.setItem("lastSelectedUser", JSON.stringify(defaultInputValue));
        return defaultInputValue;
    }

    function getLastSelectedTab() {
        return localStorage.getItem("lastSelectedTab")
            ? JSON.parse(localStorage.getItem("lastSelectedTab"))
            : "/groupA";
    }

    function getSelectedGroup(eventKey) {
        switch (eventKey) {
            case "/groupA":
                return "matches.stages.groupA";
            case "/groupB":
                return "matches.stages.groupB";
            case "/groupC":
                return "matches.stages.groupC";
            case "/groupD":
                return "matches.stages.groupD";
            case "/groupE":
                return "matches.stages.groupE";
            case "/groupF":
                return "matches.stages.groupF";
            case "/Round16":
                return "matches.stages.r16";
            case "/QF":
                return "matches.stages.qf";
            case "/SF":
                return "matches.stages.sf";
            case "/Final":
                return "matches.stages.final";
            default:
                return "matches.stages.groupA";
        }
    }

    function setCurrentTab(eventKey) {
        localStorage.setItem("lastSelectedTab", JSON.stringify(eventKey));
        setSelectedStage(getSelectedGroup(eventKey));
    }

    function getBettorNames(bettors) {
        const bettorNames = [];
        bettors.forEach((bettor) => {
            bettorNames.push(bettor.nickname);
        });
        bettorNames.sort((a, b) => a.localeCompare(b));
        return bettorNames;
    }

    function getBettorIdByName(bettorName) {
        if (bettorName !== undefined) {
            let bettor = bets.find((data) => data.nickname === bettorName);
            if (bettor !== undefined) {
                return bets.find((data) => data.nickname === bettorName).id;
            }
        }
    }

    function getBettorPoints(bettors, bettor) {
        if (getBettorNames(bettors).includes(bettor)) {
            return (
                <div id="selectedBettorTotalPoints">
                    <h4>
                        {bettor}
                        <Button
                            id="favButton"
                            variant="text"
                            onClick={() => handleToggleFavourite(bettor)}
                            title="Favorite"
                            style={{ marginTop: "-6px" }}
                        >
                            {storageItem.includes(bettor) ? (
                                <AiFillHeart color="orange" />
                            ) : (
                                <AiOutlineHeart color="error" />
                            )}
                        </Button>
                    </h4>
                    <h5>
                        {t("bets.totalPoints")}: {bettors[getBettorIdByName(bettor) - 1].points}
                    </h5>
                </div>
            );
        }
    }

    function getResultsByStage(stage, group) {
        let resultsStage;
        results.forEach((key) => {
            if (key.stage === stage && key.group === group) {
                resultsStage = key;
            }
        });
        return resultsStage;
    }

    function getBets(bettorId, stage, group) {
        let bets;
        let stageMatches = [];
        let stageAdditionals = [];
        let bettor = getBettor(bettorId);
        let groupPoints = 0;
        if (bettor !== undefined) {
            bets = {};
            bettor.bets.matches.forEach((match) => {
                if (match.stage === stage && match.group === group) {
                    stageMatches.push(match);
                    groupPoints += match.points;
                }
            });
            bettor.bets.additionals.forEach((key) => {
                if (key.stage === "GROUP_STAGE") {
                    key.groups.forEach((groupKey) => {
                        if (groupKey.group === group) {
                            stageAdditionals = groupKey;
                            groupPoints += groupKey.points;
                        }
                    });
                } else {
                    if (key.stage === stage) {
                        stageAdditionals = key;
                        groupPoints += key.points;
                    }
                }
            });
            bets["matches"] = stageMatches;
            bets["additionals"] = stageAdditionals;
            bets["points"] = groupPoints;
        }
        return bets;
    }

    function getBettor(bettorId) {
        return bets[bettorId - 1];
    }

    function getStageTotalPoints(bettorName, stage, group) {
        const totalPoints = getBettorIdByName(bettorName)
            ? getBets(getBettorIdByName(bettorName), stage, group).points
            : 0;
        return (
            <Badge bg="light" text="dark" className="stageTotalPoints">
                {totalPoints} {t("bets.pts")}
            </Badge>
        );
    }

    function renderGroups() {
        if (bettorName === "") {
            return (
                <div id="userNameAlert">
                    <Alert key="warning" variant="warning">
                        {t("bets.errorMessages.enterUser")}
                    </Alert>
                </div>
            );
        }
        if (!getBettorIdByName(bettorName)) {
            return (
                <div id="userNameAlert">
                    <Alert key="warning" variant="warning">
                        {t("bets.errorMessages.invalidUser")}
                    </Alert>
                </div>
            );
        }

        return (
            <Tab.Container defaultActiveKey={getLastSelectedTab} onSelect={setCurrentTab}>
                <Nav className="me-auto" fill justify variant="pills" id="navbar-stage-selection">
                    <Nav.Item>
                        <Nav.Link eventKey="/groupA">{t("matches.stages.groupA")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "GROUP_STAGE", "GROUP_A")}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/groupB">{t("matches.stages.groupB")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "GROUP_STAGE", "GROUP_B")}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/groupC">{t("matches.stages.groupC")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "GROUP_STAGE", "GROUP_C")}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/groupD">{t("matches.stages.groupD")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "GROUP_STAGE", "GROUP_D")}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/groupE">{t("matches.stages.groupE")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "GROUP_STAGE", "GROUP_E")}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/groupF">{t("matches.stages.groupF")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "GROUP_STAGE", "GROUP_F")}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/Round16">{t("matches.stages.r16")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "LAST_16", null)}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/QF">{t("matches.stages.qf")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "QUARTER_FINALS", null)}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/SF">{t("matches.stages.sf")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "SEMI_FINALS", null)}
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/Final">{t("matches.stages.final")}</Nav.Link>
                        <div className="stageTotalPoints">
                            {getStageTotalPoints(bettorName, "FINAL", null)}
                        </div>
                    </Nav.Item>
                </Nav>
                <Nav className="me-auto" variant="pills" id="navbar-stage-selection-dropdown">
                    <NavDropdown title={t(stage)} drop="down">
                        <NavDropdown.Item eventKey="/groupA">
                            {t("matches.stages.groupA")}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="/groupB">
                            {t("matches.stages.groupB")}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="/groupC">
                            {t("matches.stages.groupC")}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="/groupD">
                            {t("matches.stages.groupD")}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="/groupE">
                            {t("matches.stages.groupE")}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="/groupF">
                            {t("matches.stages.groupF")}
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="/Round16">
                            {t("matches.stages.r16")}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="/QF">{t("matches.stages.qf")}</NavDropdown.Item>
                        <NavDropdown.Item eventKey="/SF">{t("matches.stages.sf")}</NavDropdown.Item>
                        <NavDropdown.Item eventKey="/Final">
                            {t("matches.stages.final")}
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="/groupA" className="tab">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "GROUP_STAGE",
                                "GROUP_A"
                            )}
                            resultsLive={getResultsByStage("GROUP_STAGE", "GROUP_A")}
                            resultsLast16={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/groupB">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "GROUP_STAGE",
                                "GROUP_B"
                            )}
                            resultsLive={getResultsByStage("GROUP_STAGE", "GROUP_B")}
                            resultsLast16={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/groupC">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "GROUP_STAGE",
                                "GROUP_C"
                            )}
                            resultsLive={getResultsByStage("GROUP_STAGE", "GROUP_C")}
                            resultsLast16={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/groupD">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "GROUP_STAGE",
                                "GROUP_D"
                            )}
                            resultsLive={getResultsByStage("GROUP_STAGE", "GROUP_D")}
                            resultsLast16={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/groupE">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "GROUP_STAGE",
                                "GROUP_E"
                            )}
                            resultsLive={getResultsByStage("GROUP_STAGE", "GROUP_E")}
                            resultsLast16={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/groupF">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "GROUP_STAGE",
                                "GROUP_F"
                            )}
                            resultsLive={getResultsByStage("GROUP_STAGE", "GROUP_F")}
                            resultsLast16={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/Round16">
                        <Group
                            betsLive={getBets(getBettorIdByName(bettorName), "LAST_16", null)}
                            resultsLive={getResultsByStage("LAST_16", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/QF">
                        <Group
                            betsLive={getBets(
                                getBettorIdByName(bettorName),
                                "QUARTER_FINALS",
                                null
                            )}
                            resultsLive={getResultsByStage("QUARTER_FINALS", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/SF">
                        <Group
                            betsLive={getBets(getBettorIdByName(bettorName), "SEMI_FINALS", null)}
                            resultsLive={getResultsByStage("SEMI_FINALS", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="/Final">
                        <Group
                            betsLive={getBets(getBettorIdByName(bettorName), "FINAL", null)}
                            resultsLive={getResultsByStage("FINAL", null)}
                            updateTime={updateTime}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        );
    }
}

export default Bets;
