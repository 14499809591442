import Card from "react-bootstrap/Card";
import { format } from "date-fns";

import { useTranslation } from "react-i18next";

function DeadlineCard() {
    const { t } = useTranslation("translation");

    let todaysDate = format(new Date(), "yMMdd"); // Production: Today
    let deadlineDate = "20240613";

    if (todaysDate !== deadlineDate) return;

    return (
        <Card
            id="cardDeadline"
            style={{ marginBottom: "10px", borderColor: "white", borderWidth: "4px", borderStyle: "dashed" }}
            border="danger"
        >
            <Card.Body className="show-counter">
                <div className="countdown" style={{ display: "inline-block", textAlign: "center" }}>
                    {t("home.preTournament.deadlineText")}
                </div>
            </Card.Body>
        </Card>
    );
}

export default DeadlineCard;
