import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import * as util from "../utils/utils";
import * as constant from "../constants";

import Matches from "./tables/Matches";
import MatchesConfrontationBets from "./tables/MatchesConfrontationBets";
import GroupClassification from "./tables/GroupClassification";
import RoundPass from "./tables/RoundPass";
import Confrontation from "./tables/Confrontation";
import TournamentClassification from "./tables/TournamentClassification";
import Other from "./tables/Other";
import ToggleButton from "./buttons/ToggleButton";

function Group({ betsLive, resultsLive, resultsLast16, updateTime }) {
    const { t } = useTranslation("translation");

    const [checked, setChecked] = useState(false);

    const bets = betsLive;
    const results = resultsLive;

    if (bets === undefined || results === undefined || bets.length === 0 || results.length === 0) {
        return (
            <div className="spinner">
                <Spinner align="middle" size="lg" animation="border" variant="primary" />
            </div>
        );
    }

    function renderToggleButton(stage) {
        if (stage !== constant.stage.groupStage) {
            return (
                <div style={{ paddingBottom: "1rem" }}>
                    <h6>{t("matchViewToggle.heading")}</h6>
                    <ToggleButton
                        size="sm"
                        checked={checked}
                        offlabel="matchViewToggle.toggle-off"
                        onlabel="matchViewToggle.toggle-on"
                        width={100}
                        onChange={() => setChecked(!checked)}
                    />
                    <NavLink to="/tournamentTree">
                        <img
                            src="/images/icons/hierarchie.png"
                            height="25px"
                            alt=""
                            style={{ paddingRight: "1rem", float: "right" }}
                        />
                    </NavLink>
                </div>
            );
        }
    }

    function renderMapping() {
        if (results.stage === constant.stage.groupStage) {
            return [
                renderAccordionItem(
                    "0",
                    t("bets.categories.matches").toUpperCase(),
                    <Matches results={results} bets={bets.matches} />
                ),
                renderAccordionItem(
                    "1",
                    t("bets.categories.groupClassification").toUpperCase(),
                    <GroupClassification
                        results={results}
                        bets={bets.additionals.classification}
                        resultsKo={resultsLast16}
                    />
                ),
                renderAccordionItem(
                    "2",
                    t("bets.categories.roundPass").toUpperCase(),
                    <RoundPass results={results} bets={bets.additionals.roundPass} />
                )
            ];
        } else if (
            results.stage === constant.stage.roundOf16 ||
            results.stage === constant.stage.quarterFinals ||
            results.stage === constant.stage.semiFinals
        ) {
            return [
                renderAccordionItem(
                    "0",
                    t("bets.categories.matches").toUpperCase(),
                    !checked ? (
                        <Matches results={results} bets={bets.matches} />
                    ) : (
                        <MatchesConfrontationBets
                            results={results}
                            bets={bets.matches}
                            confrontationBets={bets.additionals.confrontations}
                        />
                    )
                ),
                renderAccordionItem(
                    "1",
                    t("bets.categories.roundPass").toUpperCase(),
                    <RoundPass results={results} bets={bets.additionals.roundPass} />
                ),
                renderAccordionItem(
                    "2",
                    t("bets.categories.confront").toUpperCase(),
                    <Confrontation results={results} bets={bets.additionals.confrontations} />
                )
            ];
        } else if (results.stage === constant.stage.final) {
            return [
                renderAccordionItem(
                    "0",
                    t("bets.categories.matches").toUpperCase(),
                    !checked ? (
                        <Matches results={results} bets={bets.matches} />
                    ) : (
                        <MatchesConfrontationBets
                            results={results}
                            bets={bets.matches}
                            confrontationBets={bets.additionals.confrontations}
                        />
                    )
                ),
                renderAccordionItem(
                    "1",
                    t("bets.categories.confront").toUpperCase(),
                    <Confrontation results={results} bets={bets.additionals.confrontations} />
                ),
                renderAccordionItem(
                    "2",
                    t("bets.categories.tournamentClassification").toUpperCase(),
                    <TournamentClassification
                        results={results}
                        bets={bets.additionals.classification}
                    />
                ),
                renderAccordionItem(
                    "3",
                    t("bets.categories.other").toUpperCase(),
                    <Other results={results} bets={bets.additionals.other} />
                )
            ];
        }
    }

    function renderAccordionItem(eventKey, heading, renderFunction) {
        return (
            <Accordion.Item eventKey={eventKey} key={eventKey}>
                <Accordion.Header className="headerLeft">
                    <Row>
                        <Col>
                            <div align="left">{heading}</div>
                        </Col>
                        <Col>
                            <div align="right">
                                {util.calcTotalPoints(renderFunction.props.bets)}{" "}
                                {t("matches.header.points")}
                            </div>
                        </Col>
                    </Row>
                </Accordion.Header>
                <Accordion.Body>{renderFunction}</Accordion.Body>
            </Accordion.Item>
        );
    }

    return (
        <div id="betsContent">
            {renderToggleButton(results.stage)}
            <Accordion id="betsAccordion" defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
                {renderMapping()}
            </Accordion>
            <div id="updateTime">
                {t("general.lastUpdated")}: {updateTime.replaceAll(".", "/")}
            </div>
        </div>
    );
}
export default Group;
