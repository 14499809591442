import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

function PointsDefinitionInfo() {
    const { t } = useTranslation("translation");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, []);

    return (
        <Container className="pageContainer">
            <h2 id="pageHeaderText">{t("pages.pageTitle.pointsDistribution")}</h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            <Table responsive borderless size="sm">
                <thead>
                    <tr>
                        <th style={{ fontWeight: 600 }}>
                            <span id="pointsDistributionStageHeading">{t("pointsDistribution.headings.stage")}</span>
                        </th>
                        <th style={{ fontWeight: 600 }}>
                            {t("pointsDistribution.headings.category")}
                        </th>
                        <th style={{ fontWeight: 600 }}>{t("pointsDistribution.headings.type")}</th>
                        <th style={{ fontWeight: 600, textAlign: "center" }}>
                            {t("pointsDistribution.headings.points")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={5}>
                            <span className="stage-label">{t("stages.long.groupStage")}</span>
                            <span className="stage-label-short">
                                {t("stages.short.groupStage")}
                            </span>
                        </td>
                        <td rowSpan={3}>{t("bets.categories.matches")}</td>
                        <td>{t("pointsDistribution.matches.tendency")}</td>
                        <td style={{ textAlign: "center" }}>5</td>
                    </tr>
                    <tr>
                        <td>{t("pointsDistribution.matches.difference")}</td>
                        <td style={{ textAlign: "center" }}>10</td>
                    </tr>
                    <tr>
                        <td>{t("pointsDistribution.matches.result")}</td>
                        <td style={{ textAlign: "center" }}>20</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.groupClassification")}</td>
                        <td style={{ textAlign: "center" }}>20</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.roundPass")}</td>
                        <td style={{ textAlign: "center" }}>25</td>
                    </tr>
                    <tr
                        style={{
                            borderTop: "6px double",
                            borderTopColor: "#f4f5f7"
                        }}
                    >
                        <td rowSpan={3}>
                            <span className="stage-label">{t("stages.long.r16")}</span>
                            <span className="stage-label-short">{t("stages.short.r16")}</span>
                        </td>
                        <td>{t("bets.categories.matches")}</td>
                        <td>{t("pointsDistribution.matches.result")}</td>
                        <td style={{ textAlign: "center" }}>10</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.confront")}</td>
                        <td style={{ textAlign: "center" }}>15</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.roundPass")}</td>
                        <td style={{ textAlign: "center" }}>30</td>
                    </tr>
                    <tr
                        style={{
                            borderTop: "6px double",
                            borderTopColor: "#f4f5f7"
                        }}
                    >
                        <td rowSpan={3}>
                            <span className="stage-label">{t("stages.long.qf")}</span>
                            <span className="stage-label-short">{t("stages.short.qf")}</span>
                        </td>
                        <td>{t("bets.categories.matches")}</td>
                        <td>{t("pointsDistribution.matches.result")}</td>
                        <td style={{ textAlign: "center" }}>10</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.confront")}</td>
                        <td style={{ textAlign: "center" }}>15</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.roundPass")}</td>
                        <td style={{ textAlign: "center" }}>40</td>
                    </tr>
                    <tr
                        style={{
                            borderTop: "6px double",
                            borderTopColor: "#f4f5f7"
                        }}
                    >
                        <td rowSpan={3}>
                            <span className="stage-label">{t("stages.long.sf")}</span>
                            <span className="stage-label-short">{t("stages.short.sf")}</span>
                        </td>
                        <td>{t("bets.categories.matches")}</td>
                        <td>{t("pointsDistribution.matches.result")}</td>
                        <td style={{ textAlign: "center" }}>10</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.confront")}</td>
                        <td style={{ textAlign: "center" }}>20</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.roundPass")}</td>
                        <td style={{ textAlign: "center" }}>50</td>
                    </tr>
                    <tr
                        style={{
                            borderTop: "6px double",
                            borderTopColor: "#f4f5f7"
                        }}
                    >
                        <td rowSpan={6}>
                            <span className="stage-label">{t("stages.long.final")}</span>
                            <span className="stage-label-short">{t("stages.short.final")}</span>
                        </td>
                        <td>{t("bets.categories.matches")}</td>
                        <td>{t("pointsDistribution.matches.result")}</td>
                        <td style={{ textAlign: "center" }}>15</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("bets.categories.confront")}</td>
                        <td style={{ textAlign: "center" }}>25</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>{t("bets.categories.tournamentClassification")}</td>
                        <td>{t("bets.tournamentClassification.winner")}</td>
                        <td style={{ textAlign: "center" }}>150</td>
                    </tr>
                    <tr>
                        <td>{t("bets.tournamentClassification.runner-up")}</td>
                        <td style={{ textAlign: "center" }}>100</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>{t("bets.categories.other")}</td>
                        <td>{t("bets.others.mvp")}</td>
                        <td style={{ textAlign: "center" }}>50</td>
                    </tr>
                    <tr>
                        <td>{t("bets.others.topScorer")}</td>
                        <td style={{ textAlign: "center" }}>50</td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
}

export default PointsDefinitionInfo;
