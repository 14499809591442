import * as constant from "../../constants";
import * as util from "../../utils/utils";

import { useState } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import Carousel from "react-bootstrap/Carousel";

import AnimatedScore from "../AnimatedScore";

function MatchesFeatured({ results, updateTime }) {
    const [slide, setSlide] = useState(1);

    const { t } = useTranslation("translation");

    function getLiveSpinner(status) {
        if (status === constant.matchStatus.in_play) {
            return <Spinner animation="grow" variant="danger" size="sm" />;
        } else if (status === constant.matchStatus.paused) {
            return (
                <img
                    src="/images/icons/halbzeit.png"
                    alt="Half Time"
                    height={"25px"}
                    style={{ marginRight: "5px", verticalAlign: "top" }}
                />
            );
        }
    }

    function renderLoadingSpinner() {
        return (
            <Spinner
                animation="border"
                variant="warning"
                style={{ display: "block", margin: "auto", marginTop: "5em", marginBottom: "5em" }}
            />
        );
    }

    function renderMatchesTable(date) {
        return (
            <>
                <Table>
                    <thead>
                        <tr className="no-border" key={v4()}>
                            <th id="matches__matchdetails">{t("matches.header.matchDetails")}</th>
                            <th id="matches__stage">{t("matches.header.stage")}</th>
                            <th id="matches__live-ticker"></th>
                            <th id="matches__score" className="align-center" colSpan={3}>
                                {t("matches.header.result")}
                            </th>
                            <th id="matches__placeholder" />
                        </tr>
                    </thead>
                    <tbody>{getMatchesTableBody(date)}</tbody>
                </Table>
                <div id="updateTime">
                    {t("home.update")} {updateTime.replaceAll(".", "/")}
                </div>
            </>
        );
    }

    function getMatches(date) {
        const matches = [];
        let dd = String(date.getDate()).padStart(2, "0");
        let mm = String(date.getMonth() + 1).padStart(2, "0");
        let yyyy = date.getFullYear();
        date = yyyy + "-" + mm + "-" + dd;
        results.forEach((stage) => {
            stage.matches.forEach((match) => {
                let matchDate = match.utcDate.substring(0, 10);
                if (matchDate === date) {
                    matches.push(match);
                }
            });
        });
        return matches;
    }

    function getMatchesTableBody(date) {
        let matches = getMatches(date);
        matches.sort((a, b) => a.utcDate.substring(11, 13) - b.utcDate.substring(11, 13));
        return matches.map((match) => (
            <tr key={match.id}>
                <td id="matches__matchdetails" className="fs-small">
                    <span id="matches__matchdetails-stage">{t(util.mapStageName(match))}</span>
                    <span id="matches__matchdetails-time">
                        {util.convertDate(match.utcDate).split("-")[1]}
                    </span>
                    <span id="matches__matchdetails-datetime">
                        {util.convertDate(match.utcDate)}
                    </span>
                    <br />
                    <span id="matches__matchdetails-venue">{match.venue}</span>
                </td>
                <td id="matches__stage">{t(util.mapStageName(match))}</td>
                <td id="matches__live-ticker" className="align-center">
                    {getLiveSpinner(match.status)}
                </td>
                <td id="matches__teamname-home">
                    <img
                        className="flag--left-aligned"
                        src={util.mapFlag(match.homeTeam.tla)}
                        alt={match.homeTeam.tla}
                        onError={util.mapDummyFlag}
                    />
                    <span className={util.getClassRoundPass(match, "home")}>
                        {t(util.mapTeamName(match.homeTeam.tla))}
                    </span>
                </td>
                <td id="matches__score" className="align-center fs-large">
                    <span className={util.getClassLiveResult(match.status)}>
                        <AnimatedScore score={util.mapScore(match.score)} />
                    </span>
                </td>
                <td id="matches__teamname-away" className="align-right">
                    <span className={util.getClassRoundPass(match, "away", "desktop")}>
                        {t(util.mapTeamName(match.awayTeam.tla))}
                    </span>
                    <img
                        className="flag--right-aligned"
                        src={util.mapFlag(match.awayTeam.tla)}
                        alt={match.awayTeam.tla}
                        onError={util.mapDummyFlag}
                    />
                    <span className={util.getClassRoundPass(match, "away", "mobile")}>
                        {t(util.mapTeamName(match.awayTeam.tla))}
                    </span>
                </td>
                <td id="matches__placeholder" />
            </tr>
        ));
    }

    function setActiveSlide(key) {
        setSlide(key);
    }

    function renderFeaturedMatches() {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return (
            <>
                <div style={{ textAlign: "center" }}>
                    <p style={{ textAlign: "center" }}>
                        {slide === 0
                            ? t("home.featuredMatches.yesterday")
                            : slide === 1
                              ? t("home.featuredMatches.today")
                              : t("home.featuredMatches.tomorrow")}
                    </p>
                </div>
                <Carousel
                    controls={false}
                    defaultActiveIndex={1}
                    interval={null}
                    onSelect={setActiveSlide}
                    style={{ minHeight: "12rem" }}
                >
                    <Carousel.Item>
                        {getMatches(yesterday).length !== 0
                            ? renderMatchesTable(yesterday)
                            : renderNoMatches()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {getMatches(today).length !== 0
                            ? renderMatchesTable(today)
                            : renderNoMatches()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {getMatches(tomorrow).length !== 0
                            ? renderMatchesTable(tomorrow)
                            : renderNoMatches()}
                    </Carousel.Item>
                </Carousel>
            </>
        );
    }

    function renderNoMatches() {
        return (
            <h3 id="pageHeaderText" style={{ textAlign: "center" }}>
                {t("pages.pageTitle.home.running.noMatchday")}
            </h3>
        );
    }

    return (
        <div>
            <h2 id="pageHeaderText">
                <img
                    src="/images/icons/fussball.png"
                    alt="Half Time"
                    height={"30px"}
                    style={{ marginRight: "1rem", verticalAlign: "bottom" }}
                />
                {t("pages.pageTitle.home.running.matchday")}
            </h2>
            <hr className="dashed" style={{ marginBottom: "2rem" }} />
            {results.length === 0 ? renderLoadingSpinner() : renderFeaturedMatches()}
        </div>
    );
}

export default MatchesFeatured;
