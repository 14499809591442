import Card from "react-bootstrap/Card";

import { useTranslation } from "react-i18next";

function DonationCard() {
    const { t } = useTranslation("translation", {
        keyPrefix: "home.after.thankYouCard"
    });

    return (
        <Card bg="light" border="secondary" style={{ marginBottom: "2rem" }}>
            <Card.Header as="h5" style={{ color: "#003566" }}>
                <img
                    src="/images/icons/party-popper.png"
                    alt="Search"
                    height={"25px"}
                    style={{ marginBottom: "0px", marginRight: "10px", verticalAlign: "bottom" }}
                />{" "}
                {t("title")}
            </Card.Header>
            <Card.Body>
                <Card.Text>{t("text")}</Card.Text>
            </Card.Body>
        </Card>
    );
}

export default DonationCard;
