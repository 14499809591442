import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

function AccordionFAQItem({ questionNumber, i18nPrefix }) {
    const { t } = useTranslation("translation");

    return (
        <Accordion.Item eventKey={questionNumber}>
            <Accordion.Header>
                {t("faq.q")}
                {questionNumber}: {t(`${i18nPrefix}.header`)}
            </Accordion.Header>
            <Accordion.Body>{t(`${i18nPrefix}.body`)}</Accordion.Body>
        </Accordion.Item>
    );
}

export default AccordionFAQItem;
