import * as util from "../../utils/utils";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

function GroupClassification({ results, bets, resultsKo }) {
    const { t } = useTranslation("translation");

    if (!results.additionals.groupClassification || !bets) {
        return null;
    }

    function markQualifierTeams(groupPosition) {
        let cssClass = "";
        resultsKo.matches.forEach((match) => {
            if (
                groupPosition.team.tla === match.homeTeam.tla ||
                groupPosition.team.tla === match.awayTeam.tla
            ) {
                if (match.homeTeam.tla !== null) {
                    cssClass = "bg-color-yellow";
                }
            }
        });
        return cssClass;
    }

    const groupClassificationTable = results.additionals.groupClassification.map((key, index) => (
        <tr className={markQualifierTeams(key)} key={index}>
            <td id="group-classification__position" className="align-center">
                {index + 1}
            </td>
            <td id="group-classification__teamname">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(key.team.tla)}
                    alt={key.team.name}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(key.team.tla))}</span>
            </td>
            <td id="group-classification__goal-difference" className="align-center">
                {util.mapGoalDiff(key)}
            </td>
            <td id="group-classification__teampoints" className="align-center">
                {key.points}
            </td>
            <td id="group-classification__joint" />
            <td id="group-classification__bet">
                <img
                    className="flag--left-aligned"
                    src={util.mapFlag(bets[index].team)}
                    alt={bets[index].team}
                    onError={util.mapDummyFlag}
                />
                <span className="team-block">{t(util.mapTeamName(bets[index].team))}</span>
            </td>
            <td id="group-classification__points" className="align-center">
                {bets[index].points}
            </td>
        </tr>
    ));

    return (
        <Container>
            <Table>
                <thead>
                    <tr className="no-border" key={v4()}>
                        <th id="group-classification__position" className="align-center">
                            {t("bets.groupTable.position")}
                        </th>
                        <th id="group-classification__teamname">{t("bets.groupTable.team")}</th>
                        <th id="group-classification__goal-difference" className="align-center">
                            {t("bets.groupTable.goals")}
                        </th>
                        <th id="group-classification__teampoints" className="align-center">
                            {t("bets.groupTable.groupPoints")}
                        </th>
                        <th id="group-classification__joint" />
                        <th id="group-classification__bet">{t("bets.groupTable.bet")}</th>
                        <th id="group-classification__points" className="align-center">
                            {t("bets.groupTable.points")}
                        </th>
                    </tr>
                </thead>
                <tbody>{groupClassificationTable}</tbody>
            </Table>
        </Container>
    );
}

export default GroupClassification;
