import { React, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

import { mediaMaxSize } from "../constants";

function AnimatedScore(props) {
    const [state, isNewScore] = useState(true);
    const [newScore, setNewScore] = useState("");

    useEffect(() => {
        if (props.score !== newScore) {
            isNewScore(!state);
            setNewScore(props.score);
        }
    }, [props.score, newScore, state]);

    const { x } = useSpring({
        from: { x: 0 },
        x: state ? 1 : 0,
        config: { duration: 1500 }
    });

    function displayScore(score) {
        return window.innerWidth <= mediaMaxSize.mobile ? score.replaceAll(" ", "") : score;
    }

    return (
        <div>
            <animated.div
                style={{
                    scale: x.to({
                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                        output: [1, 0.97, 0.9, 1.3, 0.9, 1.2, 1.03, 1]
                    })
                }}
            >
                {displayScore(newScore)}
            </animated.div>
        </div>
    );
}
export default AnimatedScore;
