import React from "react";

function Footer() {
    return (
        <div
            className="footer text-center py-3 mt-4"
            style={{ backgroundColor: "#F8F9FA", color: "#003566", fontWeight: "500" }}
        >
            © {new Date().getFullYear()} Avisporra
            <div style={{ display: "inline-block", paddingLeft: "3rem" }}>
                <a
                    href="https://www.facebook.com/Avisporra/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <img
                        src="/images/icons/facebook.png"
                        alt="Search"
                        height={"25px"}
                        style={{
                            marginBottom: "0px",
                            marginRight: "10px",
                            verticalAlign: "bottom"
                        }}
                    />
                </a>
                <a href="https://x.com/_Avisporra_" target="_blank" rel="noreferrer noopener">
                    <img
                        src="/images/icons/twitter.png"
                        alt="Search"
                        height={"25px"}
                        style={{
                            marginBottom: "0px",
                            marginRight: "10px",
                            verticalAlign: "bottom"
                        }}
                    />
                </a>
            </div>
        </div>
    );
}

export default Footer;
