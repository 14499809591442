import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";

import "./css/index.css";

import App from "./app";
import Loading from "./components/Loading";

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter basename="/">
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>
    </BrowserRouter>
);
